import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { signUp, getCurrentUser, addGroup } from "../../Utilities/firebase";

import { useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";

class AddClub extends React.Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	schools = ["northwestern"];
	componentDidMount() {
		getCurrentUser().then((user) => {
			if (!user || user.uid !== "TlGxwdz7ZZagpt2MtMRbXvaOvW32") {
				this.props.navigate("/settings");
			}
		});
	}

	async handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const clubData = {
			name: data.get("groupName"),
			description: data.get("groupDesc"),
			link: data.get("imgLink"),
		};
		addGroup(data.get("school"), clubData);
	}

	render() {
		return (
			<Container component='main' maxWidth='xs'>
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
						<GroupAddIcon />
					</Avatar>
					<Typography component='h1' variant='h5'>
						Add Groups
					</Typography>
					<Box
						component='form'
						noValidate
						onSubmit={this.handleSubmit}
						sx={{ mt: 3 }}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name='groupName'
									id='groupName'
									label='Group Name'
									autoFocus
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									multiline
									maxRows={6}
									name='groupDesc'
									id='groupDesc'
									label='Group Description'
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name='imgLink'
									id='imgLink'
									label='Image Link'
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name='school'
									id='school'
									label='School'
									defaultValue={"northwestern"}
								/>
							</Grid>
						</Grid>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2 }}>
							Add Group
						</Button>
					</Box>
				</Box>
			</Container>
		);
	}
}

export default function (props) {
	const navigate = useNavigate();
	return <AddClub {...props} navigate={navigate} />;
}
