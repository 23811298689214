import { unstable_isMuiElement } from "@mui/utils";
import React, { useContext, useState, useEffect } from "react";
import Loading from "../Components/Loading/Loading";
import { auth, getUser, getCompanies } from "../Utilities/firebase";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState();
	const [companies, setCompanies] = useState();
	const [loadingUser, setLoadingUser] = useState(true);

	useEffect(() => {
		setLoadingUser(true);
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				let userData = await getUser(user.uid);
				setCurrentUser(userData);
			} else {
				setCurrentUser(null);
			}
			let companyData = await getCompanies();
			setCompanies(companyData);
			setLoadingUser(false);
		});

		return unsubscribe;
	}, []);

	const isExec = (clubID) => {
		let isExec = false;
		currentUser.exec_for.forEach((clubRef) => {
			isExec = isExec || clubRef.id == clubID;
		});
		return isExec;
	};
	const inClub = (clubID) => {
		let inClub = false;
		currentUser.clubs.forEach((clubRef) => {
			inClub = inClub || clubRef.id == clubID;
		});
		return inClub;
	};

	const value = {
		currentUser,
		isExec,
		inClub,
		companies,
	};

	return !loadingUser ? (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	) : (
		<Loading></Loading>
	);
}
