import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getClub, getClubRef, getUser } from "../../../../Utilities/firebase";

import ClubManageTabs from "./ClubManageTabs/ClubManageTabs";
import { useAuth } from "../../../../Contexts/AuthContext";
import Loading from "../../../Loading/Loading";

const drawerWidth = 240;

class ClubManage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			club: null,
			clubRef: null,
			members: null,
			exec: null,
			requests: null,
		};
		// this.unsub = null;
	}

	async componentDidMount() {
		let requests = [];
		let members = [];
		let exec = [];
		if (!this.props.isExec(this.props.clubId.clubId)) {
			this.props.navigate("/posts");
		}
		const clubData = await getClub(
			this.props.schoolId,
			this.props.clubId.clubId
		);
		const clubRef = await getClubRef(
			this.props.schoolId,
			this.props.clubId.clubId
		);
		if (clubData == null) {
			this.props.navigate("/signin");
		} else {
			this.setState({
				club: clubData,
				clubRef: clubRef,
			});

			for (let ref of clubData.execs) {
				const uid = ref.id;
				const user = await getUser(uid);
				exec.push(user);
			}
			for (let ref of clubData.members) {
				const uid = ref.id;
				const user = await getUser(uid);
				members.push(user);
			}
			for (let ref of clubData.requested) {
				const uid = ref.id;
				const user = await getUser(uid);
				requests.push(user);
			}
			this.setState({
				members: members,
				exec: exec,
				requests: requests,
			});
		}
	}
	// componentWillUnmount() {
	// 	this.unsub();
	// }

	handleBack = () => {
		this.props.navigate(-1);
	};

	handleManageClub = () => {
		this.props.navigate(`./manage`);
	};
	handleChangeMembers = (members) => {
		this.setState({
			members: members,
		});
	};
	handleAddMembers = (newMembers) => {
		let result = this.state.members.concat(newMembers);
		this.setState({
			members: result,
		});
	};
	handleChangeExec = (exec) => {
		this.setState({
			exec: exec,
		});
	};
	handleChangeRequests = (requests) => {
		this.setState({
			requests: requests,
		});
	};

	render() {
		if (this.state.club) {
			return (
				<Box
					component='main'
					sx={{
						flexGrow: 1,
						p: 1,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
					}}>
					<Box
						container
						onClick={this.handleBack}
						style={{
							display: "flex",
							alignItems: "center",
						}}
						spacing={1}
						p={1}>
						<ArrowBackIcon m={1} />
						<Typography m={1}>Back</Typography>
					</Box>

					<Grid
						container
						direction='row'
						alignItems='center'
						mt={2}
						mb={2}
						spacing={4}>
						<Grid item>
							<Typography variant='h4'>{this.state.club.name}</Typography>
						</Grid>
					</Grid>

					<ClubManageTabs
						exec={this.state.exec}
						members={this.state.members}
						requests={this.state.requests}
						clubRef={this.state.clubRef}
						handleChangeExec={this.handleChangeExec}
						handleChangeMembers={this.handleChangeMembers}
						handleAddMembers={this.handleAddMembers}
						handleChangeRequests={this.handleChangeRequests}
					/>
				</Box>
			);
		} else {
			return <Loading></Loading>;
		}
	}
}

export default function (props) {
	const clubId = useParams();
	const { currentUser, isExec, inClub } = useAuth();
	const schoolId = currentUser.university;
	const navigate = useNavigate();
	return (
		<ClubManage
			{...props}
			navigate={navigate}
			schoolId={schoolId}
			clubId={clubId}
			currentUser={currentUser}
			isExec={isExec}
		/>
	);
}
