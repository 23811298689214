import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";

const PrivateRoute = ({ signedIn, hasCompany, reqCompany, test }) => {
	// Props are requirements for accessing this route
	// Order is important here, higher Prio requirements go last
	const auth = useAuth();
	let valid = true;
	let redirect = "/";

	function validCheck(requirement) {
		valid = valid && requirement;
	}

	// Requires a Company
	if (hasCompany) {
		valid = valid && auth.hasCompany;
		if (!auth.hasCompany) redirect = "/myOpportunities";
	}
	// Requires at least attempt to join Company
	if (reqCompany) {
		valid = valid && auth.reqCompany;
		if (!auth.reqCompany) redirect = "/joinCompany";
	} else if (reqCompany === false) {
		valid = valid && !auth.reqCompany;
		if (auth.reqCompany) redirect = "/myOpportunities";
	}

	// Requires being signed in or exlicitly CANNOT be signed in
	if (signedIn) {
		valid = valid && !!auth.currentUser;
		if (!auth.currentUser) redirect = "/signin";
	} else if (signedIn === false) {
		valid = valid && !auth.currentUser;
		if (auth.currentUser) redirect = "/myOpportunities";
	}

	if (test) {
		validCheck(auth.tester);
		if (!auth.tester) redirect = "/myOpportunities";
	}

	return valid ? <Outlet /> : <Navigate to={redirect} />;
};

export default PrivateRoute;
