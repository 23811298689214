import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

export default function FeedbackForm() {
	const [open, setOpen] = React.useState(true);

	return (
		<Box sx={{ width: "100%"}}>
			<Collapse in={open}>
				<Alert
					severity='info'
					action={
						<IconButton
							aria-label='close'
							color='inherit'
							size='small'
							onClick={() => {
								setOpen(false);
							}}>
							<CloseIcon fontSize='inherit' />
						</IconButton>
					}
					sx={{ mb: 2 }}>
					If you have any feedback or require assistance, please fill out{" "}
					<a href='https://forms.gle/o5bDVoJerm6WNRr88' target='_blank'>
						this form
					</a>
					.
				</Alert>
			</Collapse>
		</Box>
	);
}
