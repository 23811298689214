import { Box, Typography } from "@mui/material";
import React from "react";

const NoSavedPosts = () => {
    return (
        <Box
            sx={{
                p: "40px",
                m: "10px",
                borderRadius: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <Box sx={{
                display: "flex", 
                mb: 2, 
                flexDirection: "column", 
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Typography>
                    You haven't posted anything yet!
                </Typography>
                <Typography>
                    Click Post an Opportunity to create a post and share it with your groups.
                </Typography>
            </Box>
        </Box>
    );
};

export default NoSavedPosts;
