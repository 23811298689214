import React from "react";
import { styled, ButtonBase, Typography, Grid, Paper } from "@mui/material";
import { BorderColor } from "@mui/icons-material";



const OptionCard = (props) => {
	const text = props.text;
	//const isActive = parseInt(props.listing.id) === parseInt(props.activeListing);
	if(props.isActive){
		return (
			<Paper
				elevation={3}
				sx={{
					mt: 1,
					mx: 2,
					borderRadius: "10px",
					//minWidth: { xs: "100%", sm: "100%", md: "150px", lg: "250px" },

					width: { xs: "250px",  md: "250px", lg: "200px" },
					boxShadow: '0px 10px 30px rgb(72 47 215 / 20%)',
					border: 1,
					borderColor: "primary.main"
				}}
			>
				<ButtonBase
					sx={{
						width: "100%",
						borderRadius: "10px",
						px: "14px",
						py: "9px",
					}}>
					<Grid alignItems='center'>
						<Typography>{text}</Typography>
					</Grid>
				</ButtonBase>
			</Paper>
		);
	}
	else{
		return (
			<Paper
				elevation={3}
				sx={{
					mt: 1,
					mx: 2,
					borderRadius: "10px",
					//minWidth: { xs: "100%", sm: "100%", md: "150px", lg: "250px" },

					width: { xs: "250px",  md: "250px", lg: "200px" },
					boxShadow: '0px 10px 30px rgb(72 47 215 / 20%)',

				}}
			>
				<ButtonBase
					sx={{
						width: "100%",
						borderRadius: "10px",
						px: "14px",
						py: "9px",
					}}>
					<Grid alignItems='center'>
						<Typography>{text}</Typography>
					</Grid>
				</ButtonBase>
			</Paper>
		);
			}
};


export default OptionCard;