import * as React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { IconButton } from "@mui/material";
import { green } from "@mui/material/colors";

const AcceptButton = ({ handleAccept }) => {
	return (
		<IconButton
			sx={{
				borderRadius: "50%",
				border: 2,
				borderColor: green[500],
			}}
			style={{ maxWidth: "30px", maxHeight: "30px" }}
			onClick={handleAccept}>
			<CheckIcon style={{ color: green[500] }} />
		</IconButton>
	);
};

export default AcceptButton;
