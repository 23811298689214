import { Box, Typography } from "@mui/material";
import React from "react";

const NotExec = () => {
    return (
        <Box
            sx={{
                p: "40px",
                m: "10px",
                borderRadius: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <Box sx={{
                display: "flex", 
                mb: 2, 
                flexDirection: "column", 
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Typography>
                    You are not on leadership for any groups yet.
                </Typography>
                <Typography>
                    Please reach out to someone on your group's leadership or <a href="mailto:han@joininbound.com">han@joininbound.com</a> for assistance.
                </Typography>
            </Box>
        </Box>
    );
};

export default NotExec;
