import { Box, Stack, Typography, IconButton, Switch } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useEffect } from "react";

import { useAuth } from "../../Contexts/AuthContext";

import ExpandedCard from "./ExpandedCard";
import ListingsBox from "./ListingsBox";
import SearchBar from "./SearchBar";
import SearchIcon from "@mui/icons-material/Search";
import MobileCard from "./MobileCard";
import loading from "../DummyData/Assets/loading";
import {
	getClubFromReference,
	getPostFromReference,
} from "../../Utilities/firebase";

import NoPosts from "./NoPosts";
import FeedbackForm from "../Common/FeedbackForm";
import { useNavigate } from "react-router-dom";

import NoSavedPosts from "./NoSavedPosts";
import Loading from "../Loading/Loading";

function sortByDate(posts) {
	var deadlineArr = [];
	var objectHash = {};
	for (const post in posts) {
		const deadlineUnix = posts[post]["date_deadline"].getTime();
		objectHash[deadlineUnix] = post;
		deadlineArr.push(deadlineUnix);
	}
	deadlineArr.sort();
	const finalMap = {};
	for (let i = 0; i < deadlineArr.length; i++) {
		finalMap[objectHash[deadlineArr[i]]] = posts[objectHash[deadlineArr[i]]];
	}
	return finalMap;
}

const ViewPosts = () => {
	const [listings, setListings] = useState(null);
	const [activeId, setActiveId] = useState(null);
	const [togglePosts, setTogglePosts] = useState(false);
	const [groupListings, setGroupListings] = useState(null);
	const [groupActiveId, setGroupActiveId] = useState(null);
	const [userExec, setUserExec] = useState(false);
	const [open, setOpen] = React.useState(false);
	const { currentUser } = useAuth();
	const matches = useMediaQuery("(min-width:900px)");
	const today = new Date();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			if (!currentUser) {
				navigate("/signin");
				return;
			}
			if (currentUser.exec_for.length) {
				setUserExec(true);
			}
			let posts = {};
			for (let clubRef of currentUser.clubs) {
				let club = await getClubFromReference(clubRef);
				for (let postRef of club.posts) {
					const postID = postRef.id;
					if (postID in posts) {
						posts[postID].clubs.push({ name: club.name, imageUrl: club.logo });
					} else {
						let post = await getPostFromReference(postRef);
						let curr_deadline = post.date_deadline.toDate();
						if (curr_deadline < today) {
							continue;
						}
						posts[postID] = post;
						posts[postID].date_created = posts[postID].date_created.toDate();
						posts[postID].date_deadline = posts[postID].date_deadline.toDate();
						posts[postID].clubs = [{ name: club.name, imageUrl: club.logo }];
					}
				}
			}
			posts = sortByDate(posts);
			setActiveId(Object.keys(posts)[0]);
			setListings(posts);
			let groupPosts = {};
			for (let clubRef of currentUser.exec_for) {
				let club = await getClubFromReference(clubRef);
				for (let postRef of club.group_posts) {
					const postID = postRef.id;
					if (postID in groupPosts) {
						groupPosts[postID].clubs.push({
							name: club.name,
							imageUrl: club.logo,
						});
					} else {
						let post = await getPostFromReference(postRef);
						let curr_deadline = post.date_deadline.toDate();
						if (curr_deadline < today) {
							continue;
						}
						groupPosts[postID] = post;
						groupPosts[postID].date_created =
							groupPosts[postID].date_created.toDate();
						groupPosts[postID].date_deadline =
							groupPosts[postID].date_deadline.toDate();
						groupPosts[postID].clubs = [
							{ name: club.name, imageUrl: club.logo },
						];
					}
				}
			}
			groupPosts = sortByDate(groupPosts);
			setGroupActiveId(Object.keys(groupPosts)[0]);
			setGroupListings(groupPosts);
		};

		fetchData().catch(console.error);
	}, [currentUser]);

	const handleCardClick = (cardId) => {
		setActiveId(cardId);
		if (!matches) {
			setOpen(true);
		}
	};

	const handleGroupCardClick = (cardId) => {
		setGroupActiveId(cardId);
		if (!matches) {
			setOpen(true);
		}
	};

	const handleTogglePosts = () => {
		setTogglePosts(!togglePosts);
	};

	const handleClose = () => {
		setOpen(false);
	};
	// Posts loaded
	if (listings) {
		// Case 1: All Posts
		if (!togglePosts) {
			// Subcase 1: No Posts
			if (!activeId) {
				return <NoPosts />;
			}
			// Subcase 2: Default
			else {
				return (
					<Box sx={{ mx: { xs: "0px", lg: "100px" } }}>
						<Stack>
							<Box
								sx={{
									display: "flex",
									gap: "100px",
									justifyContent: "space-between",
									alignItems: "center",
									mx: { xs: "10px", lg: "0px" },
								}}>
								<Typography variant='h5' fontWeight={"medium"}>
									Latest Opportunities
								</Typography>
								{/* <SearchBar sx={{ display: { xs: "none", md: "block" } }} /> 
			<IconButton sx={{ display: { xs: "block", md: "none" } }}>
				<SearchIcon sx={{ color: "primary.main" }} />
			</IconButton>*/}
							</Box>
							{/* Toggle Posts */}
							<Stack
								direction='row'
								spacing={1}
								alignItems='center'
								sx={{
									display: userExec ? "flex" : "none",
								}}>
								<Typography
									sx={{
										fontWeight: togglePosts ? "regular" : "bold",
									}}>
									All Posts
								</Typography>
								<Switch onClick={handleTogglePosts} />
								<Typography
									sx={{
										fontWeight: togglePosts ? "bold" : "regular",
									}}>
									My Posts
								</Typography>
							</Stack>
						</Stack>
						<Stack direction={"row"} sx={{ mb: 2 }}>
							<ListingsBox
								clickFunction={handleCardClick}
								activeId={activeId}
								posts={listings}
							/>
							<ExpandedCard listing={listings[activeId]} />
							<MobileCard
								listing={listings[activeId]}
								handleClose={handleClose}
								open={open}
							/>
						</Stack>
						<FeedbackForm />
					</Box>
				);
			}
		}
		// Case 2: Group Posts
		else {
			// Subcase 1: No Posts
			if (!groupActiveId) {
				return (
					<Box sx={{ mx: { xs: "0px", lg: "100px" } }}>
						<Stack>
							<Box
								sx={{
									display: "flex",
									gap: "100px",
									justifyContent: "space-between",
									alignItems: "center",
									mx: { xs: "10px", lg: "0px" },
								}}>
								<Typography variant='h5' fontWeight={"medium"}>
									Latest Opportunities
								</Typography>
								{/* <SearchBar sx={{ display: { xs: "none", md: "block" } }} /> 
							<IconButton sx={{ display: { xs: "block", md: "none" } }}>
								<SearchIcon sx={{ color: "primary.main" }} />
							</IconButton>*/}
							</Box>
							{/* Toggle Posts */}
							<Stack direction='row' spacing={1} alignItems='center'>
								<Typography
									sx={{
										fontWeight: togglePosts ? "regular" : "bold",
									}}>
									All Posts
								</Typography>
								<Switch onClick={handleTogglePosts} />
								<Typography
									sx={{
										fontWeight: togglePosts ? "bold" : "regular",
									}}>
									My Posts
								</Typography>
							</Stack>
						</Stack>
						<NoSavedPosts />
						<FeedbackForm />
					</Box>
				);
			}
			// Subcase 2: Default
			else {
				return (
					<Box sx={{ mx: { xs: "0px", lg: "100px" } }}>
						<Stack>
							<Box
								sx={{
									display: "flex",
									gap: "100px",
									justifyContent: "space-between",
									alignItems: "center",
									mx: { xs: "10px", lg: "0px" },
								}}>
								<Typography variant='h5' fontWeight={"medium"}>
									Latest Opportunities
								</Typography>
								{/* <SearchBar sx={{ display: { xs: "none", md: "block" } }} /> 
							<IconButton sx={{ display: { xs: "block", md: "none" } }}>
								<SearchIcon sx={{ color: "primary.main" }} />
							</IconButton>*/}
							</Box>
							{/* Toggle Posts */}
							<Stack
								direction='row'
								spacing={1}
								alignItems='center'
								sx={{
									display: userExec ? "flex" : "none",
								}}>
								<Typography
									sx={{
										fontWeight: togglePosts ? "regular" : "bold",
									}}>
									All Posts
								</Typography>
								<Switch onClick={handleTogglePosts} />
								<Typography
									sx={{
										fontWeight: togglePosts ? "bold" : "regular",
									}}>
									My Posts
								</Typography>
							</Stack>
						</Stack>
						<Stack direction={"row"} sx={{ mb: 2 }}>
							<ListingsBox
								clickFunction={handleGroupCardClick}
								activeId={groupActiveId}
								posts={groupListings}
							/>
							<ExpandedCard listing={groupListings[groupActiveId]} />
							<MobileCard
								listing={groupListings[groupActiveId]}
								handleClose={handleClose}
								open={open}
							/>
						</Stack>
						<FeedbackForm sx={{ mt: 2 }} />
					</Box>
				);
			}
		}
	}
	// Posts not loaded
	else {
		return <Loading></Loading>;
	}
};

export default ViewPosts;
