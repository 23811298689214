import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Grid, Stack, Button } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Contexts/AuthContext";
import { getClub, removeUser } from "../../../Utilities/firebase";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../Loading/Loading";

const drawerWidth = 240;

const ClubDetails = ({ currentUser, isExec, inClub, clubId, schoolId }) => {
	const [club, setClub] = useState(null);

	const user_id = currentUser.user_id;
	const exec = isExec(clubId);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			if (inClub(clubId)) {
				const clubData = await getClub(schoolId, clubId);
				if (clubData == null) {
					navigate("/signin");
				} else {
					setClub(clubData);
				}
			} else {
				navigate("/posts");
			}
		};
		fetchData();
	}, []);

	const handleLeaveClub = async () => {
		let uid = user_id;
		await removeUser(uid, schoolId, clubId);
		navigate("/settings/groups");
	};

	const handleBack = () => {
		navigate(-1);
	};

	const handleManageClub = () => {
		navigate(`./manage`);
	};

	if (club !== null && user_id !== null) {
		return (
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					p: 1,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}>
				<Box
					container
					onClick={handleBack}
					style={{
						display: "flex",
						alignItems: "center",
					}}
					spacing={1}
					p={1}>
					<ArrowBackIcon m={1} />
					<Typography m={1}>Back</Typography>
				</Box>

				<Grid
					container
					direction='row'
					alignItems='center'
					mt={2}
					mb={2}
					spacing={4}>
					<Grid item>
						<Stack
							justifyContent='center'
							alignItems='center'
							sx={{
								borderRadius: "50%",
								width: 200,
								height: 200,
								boxShadow: 4,
								border: 2,
								borderColor: "primary.main",
							}}>
							<img
								src={club.logo}
								style={{
									objectFit: "contain",
									maxWidth: 125,
									maxHeight: 125,
								}}
								alt={`${club.name} Logo`}
							/>
						</Stack>
					</Grid>
					<Grid item>
						<Typography variant='h4'>{club.name}</Typography>
					</Grid>
				</Grid>

				<Box
					sx={{
						display: "flex",
						alignItems: "flex-start",
						flexDirection: "column",
					}}>
					<Typography variant='h5'>Options</Typography>
					<Button onClick={handleLeaveClub}>Leave</Button>
					{exec ? (
						<Button onClick={handleManageClub}>Manage Members</Button>
					) : (
						<></>
					)}
				</Box>
			</Box>
		);
	} else {
		return <Loading></Loading>;
	}
};

export default function (props) {
	const clubId = useParams();
	const { currentUser, isExec, inClub } = useAuth();
	const schoolId = currentUser.university;

	return (
		<ClubDetails
			{...props}
			schoolId={schoolId}
			clubId={clubId.clubId}
			currentUser={currentUser}
			isExec={isExec}
			inClub={inClub}
		/>
	);
}
