import React from 'react';
import { Box, Stack, Typography } from "@mui/material";
import { version } from "./PrivacyPolicy"

const InboundTermsOfService = () => {

  return (
    <Box sx={{
      paddingX: 10
    }}>
      <Typography variant="h4" sx={{ marginY: 2 }}>
        Inbound Terms of Service
      </Typography>
      <Typography variant="h5" sx={{ marginBottom: 4}}>
        Version {version} - Last Updated 10/25/2022
      </Typography>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          1. Agreement to Terms        </Typography>
        <Typography >
          These Terms of Use govern your access to and use of Inbound Careers website, apps, APIs, and all other services rendered by Inbound Careers (“Inbound”, the “Site” or the “Service”). Please read these Terms carefully and reach out with any questions (see below: Contact US). </Typography>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          2. Privacy
        </Typography>
        <Typography >
          Your use of Inbound Careers is subject to our Privacy Policy. Please review our Privacy Policy, which informs you of Inbound’s data collection and usage policy.        </Typography>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          3. Your Account
        </Typography>
        <Typography >
          If you use Inbound, you are responsible for maintaining the confidentiality of your account and password, and you agree to accept responsibility for all activities that occur under your account and password. You may not assign or otherwise transfer your account to any other entity. You acknowledge that Inbound Careers is not responsible for third party access to your account that results from theft or misappropriation of your account. Inbound Careers reserve the right to refuse or cancel service, terminate accounts, or remove or edit content at our discretion.        </Typography>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          4. No Unlawful or Prohibited Use/Intellectual Property        </Typography>
          <Stack spacing={{ xs: 3, sm: 3, md: 3}}>
        <Typography>
          You are granted a non-exclusive, non-transferable, revocable license to access and use Inbound Careers strictly in accordance with these Terms of Use. As a condition of your use of the Site, you may not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any way which could damage, disable, burden, or impair the Site or interfere with any other party’s use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.
          </Typography >
          <Typography >
          All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site is the property of Inbound Careers or its suppliers and protected by copyright and intellectual property laws. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.
          </Typography >
          <Typography >
          You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Inbound Careers content is not for resale.        </Typography>
          </Stack>
      </Stack>


      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          5. Materials Provided to Inbound Careers or Posted on the Site        </Typography>
        <Typography >
          These Terms of Use govern your access to and use of Inbound Careers website, apps, APIs, and all other services rendered by Inbound Careers (“Inbound”, the “Site” or the “Service”). Please read these Terms carefully and reach out with any questions (see below: Contact US).
        </Typography>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          6. Governing Law
        </Typography>
        <Typography >
          The Terms of Use contract shall be governed by and interpreted in accordance with the laws of the State of Delaware without regard to its conflict of law principles.        </Typography>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          7. Indemnification        </Typography>
        <Typography >
          You agree to indemnify, defend and hold harmless Inbound Careers, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney’s fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules, or regulations. Inbound Careers reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Inbound Careers in asserting any available defenses.        </Typography>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          8. Arbitration        </Typography>
        <Typography >
          For any disputes you have with Inbound Careers, you agree to first contact us and try to resolve the dispute informally. If Inbound has not been able to resolve the dispute with you informally, we each agree to resolve any claim, dispute, or controversy arising out of or in connection with these Terms through binding arbitration or in small claims court (when applicable for qualifying claims). Any arbitration will be administered by the American Arbitration Association under the Consumer Arbitration Rules in effect at the time. Each party will be responsible for paying any filing, administrative, and arbitrator fees in accordance with AAA Rules. All claims must be brought in the parties individual capacity, and not as a plaintiff or class member in any purported class or presentative proceeding. You agree by entering into this, you and Inbound are each waiving the right to a trial by jury or to participate in a class action.        </Typography>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          9. Class Action Waiver        </Typography>
        <Typography >
          The Parties waive any right to assert any claims against the other party as a representative or member in any class or representative action, except where such waiver is prohibited by law or deemed by a court of law to be against public policy. To the extent either party is permitted by law or court of law to proceed with a class or representative action against the other, the parties agree that: (i) the prevailing party shall not be entitled to recover attorneys’ fees or costs associated with pursuing the class or representative action (notwithstanding any other provision in this agreement); and (ii) the party who initiates or participates as a member of the class will not submit a claim or otherwise participate in any recovery secured through the class or representative action.        </Typography>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          10. Liability Disclaimer        </Typography>

          <Stack spacing={3}>
        <Typography >
          The information, software, products, and services included in or available through Inbound Careers may include inaccuracies or typographical errors. Inbound Careers makes no guarantee as to the availability, timeliness, and accuracy of the information included on the Site. Inbound Careers and its Suppliers may make improvements and/or changes to the Site at any time. Inbound Careers disclaims all warranties and conditions with regard to information, software, products, and services.
          </Typography>

          <Typography>

          To the maximum extent permitted by applicable law, in no event shall Inbound Careers be liable for any damages whatsoever including damages for loss of use or data or profits arising out of or in any way connected with the use or performance of the site, the delay or inability to use the site or related services, the provision or failure to provide services. If you are dissatisfied with Inbound Careers or any portion of the services it provides, your sole and exclusive remedy is to discontinue using the site.        </Typography>
          </Stack>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          11. Termination/Access Restriction        </Typography>
        <Typography >
          Inbound Careers reserves the right to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Delaware and you hereby consent to the exclusive jurisdiction and venue of courts in Delaware in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.        </Typography>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          12. Changes to Terms        
        </Typography>
        <Typography >
          Inbound Careers reserves the right to change the Terms of Use. The most current version of the Terms of Use will supersede all previous versions. We encourage you to periodically review the Terms of Use to stay informed of our updates.        </Typography>
      </Stack>


      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
          13. Contact
          </Typography>
          <Typography >
          You can contact us with questions about the terms of use at the following address: <a href={`mailto:han@joininbound.com`}>han@joininbound.com</a>     </Typography>
      </Stack>
    </Box>
  )
}

export default InboundTermsOfService;
