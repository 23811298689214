import * as React from "react";
import {
	Box,
	TextField,
	Typography,
	Button,
	MenuItem,
	Stack,
	FormControl,
	InputLabel,
	Select,
	Checkbox,
	ListItemText,
	Chip,
} from "@mui/material";
import { updateUser, getUser, getCurrentUser, changePassword } from "../../Utilities/firebase";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";

const drawerWidth = 240;

const universityOptions = [
	{ text: "Dartmouth University" },
	{ text: "Northwestern University" },
	{ text: "Stanford University" },
	{ text: "UCLA" },
	{ text: "University of Chicago" },
	{ text: "UIUC" },
	{ text: "UC Berkeley" },
	
]

const majorOptions = [
	{ text: "African American Studies" },
	{ text: "African Studies" },
	{ text: "American Studies" },
	{ text: "Anthropology" },
	{ text: "Applied Mathematics" },
	{ text: "Art History" },
	{ text: "Art Theory and Practice" },
	{ text: "Asian American Studies" },
	{ text: "Asian Languages and Cultures" },
	{ text: "Biological Sciences" },
	{ text: "Biomedical Engineering" },
	{ text: "Chemical Engineering" },
	{ text: "Chemistry" },
	{ text: "Civil Engineering" },
	{ text: "Classics" },
	{ text: "Cognitive Science" },
	{ text: "Communication Studies" },
	{ text: "Comparative Literary Studies" },
	{ text: "Composition" },
	{ text: "Computer Engineering" },
	{ text: "Computer Science" },
	{ text: "Creative Writing" },
	{ text: "Dance" },
	{ text: "Data Science" },
	{ text: "Earth and Planetary Sciences" },
	{ text: "Economics" },
	{ text: "Electrical Engineering" },
	{ text: "Elementary Teaching" },
	{ text: "English and American Literature" },
	{ text: "Environmental Engineering" },
	{ text: "Environmental Sciences" },
	{ text: "French" },
	{ text: "Gender and Sexuality Studies" },
	{ text: "Geography" },
	{ text: "German" },
	{ text: "Global Health Studies" },
	{ text: "History" },
	{ text: "Human Communication Sciences" },
	{ text: "Human Development in Context" },
	{ text: "Industrial Engineering" },
	{ text: "Integrated Science" },
	{ text: "International Studies" },
	{ text: "Italian Literature and Culture" },
	{ text: "Jazz Studies" },
	{ text: "Jewish Studies" },
	{ text: "Journalism" },
	{ text: "Latina and Latino Studies" },
	{ text: "Learning and Organizational Change" },
	{ text: "Learning Sciences" },
	{ text: "Legal Studies" },
	{ text: "Linguistics" },
	{ text: "Manufacturing and Design Engineering" },
	{ text: "Materials Science" },
	{ text: "Mathematics" },
	{ text: "Mechanical Engineering" },
	{ text: "Middle East and North African Studies" },
	{ text: "MMSS" },
	{ text: "Music Cognition" },
	{ text: "Music Education" },
	{ text: "Music Theory" },
	{ text: "Musicology" },
	{ text: "Neuroscience" },
	{ text: "Performance Studies" },
	{ text: "Philosophy" },
	{ text: "Physics" },
	{ text: "Piano" },
	{ text: "Political Science" },
	{ text: "Psychology" },
	{ text: "Radio/Television/Film" },
	{ text: "Religious Studies" },
	{ text: "Science in Human Culture" },
	{ text: "Slavic Languages and Literatures" },
	{ text: "Social Policy" },
	{ text: "Sociology" },
	{ text: "Spanish" },
	{ text: "Statistics" },
	{ text: "String Instruments" },
	{ text: "Theatre" },
	{ text: "Voice and Opera" },
	{ text: "Winds and Percussion Instruments" },
]

const raceOptions = [
	{ text: "American Indian or Alaska Native" },
	{ text: "Asian" },
	{ text: "Black or African American" },
	{ text: "Hispanic or Latino" },
	{ text: "Native Hawaiian or Other Pacific Islander" },
	{ text: "White" },
];

const genderOptions = [
	{ text: "Female" },
	{ text: "Male" },
	{ text: "Non-binary" },
	{ text: "Transgender" },
	{ text: "Intersex" },
	{ text: "Prefer Not to Say" },
];

const months = [
	{ value: 1, label: "January" },
	{ value: 2, label: "February" },
	{ value: 3, label: "March" },
	{ value: 4, label: "April" },
	{ value: 5, label: "May" },
	{ value: 6, label: "June" },
	{ value: 7, label: "July" },
	{ value: 8, label: "August" },
	{ value: 9, label: "September" },
	{ value: 10, label: "October" },
	{ value: 11, label: "November" },
	{ value: 12, label: "December" },
];

const years = [
	{ value: 2022, label: "2022" },
	{ value: 2023, label: "2023" },
	{ value: 2024, label: "2024" },
	{ value: 2025, label: "2025" },
	{ value: 2026, label: "2026" },
	{ value: 2027, label: "2027" },
];

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		// this.handleMonthChange = this.handleMonthChange.bind(this);
		// this.handleYearChange = this.handleYearChange.bind(this);
		this.state = {
			user: null,
			// month: 1,
			// year: 2022,
			majors: [],
			selectedUniversity: '',
			selectedMajor: null,
		};
	}

	async componentDidMount() {
		await getCurrentUser().then(async (user) => {
			if (user == null) {
				this.props.navigate("/signin");
			} else {
				var userData = await getUser(user.uid);
	
				// Ensure 'race' field is an array
				const userRace = Array.isArray(userData.race) ? userData.race : userData.race ? [userData.race] : [];
	
				this.setState({
					user: {
						...userData,
						race: userRace
					},
					majors: [],
					selectedUniversity: userData.university === 'northwestern' ? 'Northwestern University' : userData.university || '',
					selectedMajor: userData.major || '',
				});
				// getUser(user.uid).then((userdata) => {
				//     this.setState({
				//         user: userdata,
				//     });
				// if (this.state.user) {
				//     const initial_month = this.state.user;
				//     const initial_year = this.state.user;
				//     this.setState({
				//         month: initial_month,
				//         year: initial_year,
				//     });
				// }
				// });
				// Helper function to capitalize the first letter of each word in a string
				function toTitleCase(str) {
					return str.replace(/\w\S*/g, function (word) {
					return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
					});
				}
				
				try {
					const response = await fetch(
					"https://fivethirtyeight.datasettes.com/fivethirtyeight/college-majors~2Fmajors-list.json"
					);
					const data = await response.json();
					const majors = data.rows.map((row) => toTitleCase(row.Major));
					
					// Alphabetize the majors array
					majors.sort((a, b) => a.localeCompare(b));
					
					this.setState({
					majors: majors,
					});
				} catch (error) {
					console.error(error);
				}
				
			}
		});
	}

	handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		console.log(data.get("major"))
		const updatedUser = {
			...this.state.user,
			first_name: data.get("first_name"),
			last_name: data.get("last_name"),
			grad_month: data.get("grad_month"),
			grad_year: data.get("grad_year"),
			university: data.get("university"),
			major: this.state.selectedMajor,
			race: this.state.user.race,
			gender: data.get("gender"),
		};
		await updateUser(this.state.user.user_id, updatedUser);
		alert("Your changes have been saved.");
	};

	async handleChangePassword(email) {
		await changePassword(email);
	}
	
	handleChange = (event) => {
		const value = event.target.value;
		this.setState((prevState) => ({
		  user: {
			...prevState.user,
			race: value,
		  },
		}));
	  };
	  

	/*
	handleMonthChange = (event) => {
		event.preventDefault();
		this.setState({
			month: event.target.value,
		});
	};

	handleYearChange = (event) => {
		event.preventDefault();
		this.setState({
			year: event.target.value,
		});
	};
	*/

	render() {
		if (this.state.user) {
			return (
				<Box
					sx={{
						flexGrow: 1,
						p: 1,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						"& .MuiTextField-root": { m: 1, width: "25ch" },
					}}
					onSubmit={this.handleSubmit}
					noValidate
					autoComplete='off'
					component='form'>
					<Typography variant='h3' mb={2}>
						Profile
					</Typography>
					<Typography variant='body1' mb={1}>
						Please fill out all required entries to view job opportunities.
					</Typography>
					<Box>
						<TextField
							required
							id='first_name'
							name='first_name'
							label='First Name'
							defaultValue={this.state.user.first_name}
						/>
						<TextField
							required
							id='last_name'
							name='last_name'
							label='Last Name'
							defaultValue={this.state.user.last_name}
						/>
					</Box>
					<Box>
						<TextField
							required
							select
							id='grad_month'
							name='grad_month'
							label='Graduation Month'
							defaultValue={
								this.state.user.grad_month === -1
									? null
									: this.state.user.grad_month
							}
						>
							{months.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
						<TextField
							required
							select
							id='grad_year'
							name='grad_year'
							label='Graduation Year'
							defaultValue={
								this.state.user.grad_year === -1
									? null
									: this.state.user.grad_year
							}
						>
							{years.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</Box>
					<Box>
						<TextField
							id='university'
							name='university'
							label='University'
							defaultValue={
								this.state.user.university === "northwestern" ? "Northwestern University" : this.state.user.university
							}
							disabled
							sx={{
								minWidth: 400,
							}}>
						</TextField>
						<TextField
							select
							id='major'
							name='major'
							label='Major'
							defaultValue={this.state.selectedMajor}
							onChange={(event) => this.setState({ selectedMajor: event.target.value })}
							sx={{
								minWidth: 400,
							}}>
							{(this.state.selectedUniversity === "Northwestern University"
								? majorOptions
								: this.state.majors
							).map((option) => (
								<MenuItem
								key={option.text || option}
								value={option.text || option}
								sx={{
									minWidth: 400,
								}}
								>
								{option.text || option}
								</MenuItem>
							))}
						</TextField>
					</Box>
					<Stack direction="row" spacing={2}>
						<FormControl
							id='race'
							name='race'
							sx={{
								minWidth: 400,
								maxWidth: 400,
								maxHeight: 10,
								margin: 1, // Add margin to the top
								height: 'auto',
							}}
							>
							<InputLabel>Race</InputLabel>
							<Select
								multiple
								value={this.state.user.race && this.state.user.race.length > 0 ? (Array.isArray(this.state.user.race) ? this.state.user.race : [this.state.user.race]) : []}
								onChange={this.handleChange}
								renderValue={(selected) => (
								<div>
									{selected.map((value) => (
									<Chip key={value} label={value} />
									))}
								</div>
								)}
								sx={{ minWidth: 400 }}
								MenuProps={{
								PaperProps: {
									style: {
									maxHeight: 224,
									width: 250,
									},
								},
								}}
							>
								{raceOptions.filter((option) => option.text).map((option) => (
								<MenuItem key={option.text} value={option.text} sx={{ my: 0.5 }}>
									<Checkbox checked={this.state.user.race && this.state.user.race.includes(option.text)} />
									<ListItemText primary={option.text} />
								</MenuItem>
							))}
							</Select>
						</FormControl>
						<TextField
							select
							id='gender'
							name='gender'
							label='Gender'
							defaultValue={
								this.state.user.gender === "" ? null : this.state.user.gender
							}
							sx={{
								minWidth: 400,
							}}>
							{genderOptions.map((option) => (
								<MenuItem
									key={option.text}
									value={option.text}
									sx={{
										minWidth: 400,
									}}>
									{option.text}
								</MenuItem>
							))}
						</TextField>
					</Stack>
					<Stack direction="row">
						<Button
							type='submit'
							variant='contained'
							sx={{ m: 1, backgroundColor: "primary" }}>
							Save Changes
						</Button>
						<Button
							onClick={() => this.handleChangePassword(this.state.user.email)}
						>
							Change Password
						</Button>
					</Stack>
					{/*<div> <Button variant="contained" component="label" sx={{ m: 1, backgroundColor: 'primary' }} > Upload Resume <input type="file" hidden accept=".doc, .docx, .pdf" /> </Button> </div>*/}
				</Box>
			);
		} else {
			return <Loading></Loading>;
		}
	}
}

export default function (props) {
	const navigate = useNavigate();
	return <Profile {...props} navigate={navigate} />;
}

export { universityOptions };