import { Box } from "@mui/material";
import React from "react";
import ListingCard from "./ListingCard";

const ListingsBox = ({ posts, activeId, clickFunction }) => {
	const postsArr = Object.values(posts);
	return (
		<Box
			sx={{
				width: { xs: "100%", sm: "95%" },
				mr: "20px",
				maxWidth: { md: "530px" },
				maxHeight: "75vh", 
				display: "flex",
				flexDirection: "column"
			}}>
			<Box sx = {{
flex: 1, overflow: "auto"			}}>
				{postsArr.map((listing) => {
					if (listing.id === activeId) {
						return (
							<ListingCard
								isActive={true}
								key={listing.id}
								listing={listing}
								clickFunction={clickFunction}
							/>
						);
					}
					return (
						<ListingCard
							key={listing.id}
							isActive={false}
							listing={listing}
							clickFunction={clickFunction}
						/>
					);
				})}
		</Box>
		</Box>
	);
};

export default ListingsBox;
