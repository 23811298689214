import * as React from "react";
import Box from "@mui/material/Box";
import { Typography, Grid, Stack } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Contexts/AuthContext";

import { getClubFromReference } from "../../../Utilities/firebase";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../Loading/Loading";


const drawerWidth = 240;

const Clubs = () => {
	const [clubs, setClubs] = useState(null);
	const [loading, setLoading] = useState(true);
	const { currentUser } = useAuth();
	const navigate = useNavigate();

	const handleOpenClub = (clubId) => {
		navigate(`./${clubId}`);
	};

	useEffect(() => {
		const fetchData = async () => {
			var clubsData = null;
			if (currentUser.clubs !== []) {
				clubsData = [];

				// Loop through each club
				for (let i = 0; i < currentUser.clubs.length; i++) {
					await getClubFromReference(currentUser.clubs[i]).then(
						async (club) => {
							if (club != null) {
								clubsData.push(club);
							}
						}
					);
				}
				setClubs(clubsData);
				setLoading(false);
			}
		};
		fetchData();
	}, []);

	if (clubs && currentUser) {
		return (
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					p: 1,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}>
				<Typography variant='h3' mb={2}>
					Groups
				</Typography>

				<Typography variant='h5' mb={2}>
					Choose an organization
				</Typography>

				{/*<Typography
					variant='body1'
					sx={{
						fontStyle: "italic",
					}}
					mb={2}>
					Please contact your group leadership if you are not in a group.
				</Typography>*/}

				<Grid
					container
					spacing={{ xs: 2, md: 3 }}
					columns={{ xs: 4, sm: 8, md: 12 }}>
					{clubs.map((club) => (
						<Grid item onClick={() => handleOpenClub(club.id)} key={club.id}>
							<Stack
								spacing={2}
								sx={{
									justifyContent: "center",
									alignItems: "center",
									textAlign: "center",
									borderRadius: "15px",
									width: 250,
									height: 350,
									boxShadow: 4,
								}}
								p={2}>
								<img
									src={club.logo}
									style={{
										objectFit: "contain",
										maxWidth: "70%",
										maxHeight: "70%",
									}}
									alt={`${club.name} Logo`}
								/>
								{club.execs.includes(currentUser.user_id) ? (
									<Typography
										sx={{
											fontWeight: "bold",
										}}>{`${club.name} Executive`}</Typography>
								) : (
									<Typography sx={{ fontWeight: "bold" }}>
										{club.name}
									</Typography>
								)}
							</Stack>
						</Grid>
					))}
					<Grid item onClick={() => navigate("/joingroups")} key={clubs.length}>
						<Stack
							spacing={2}
							sx={{
								justifyContent: "center",
								alignItems: "center",
								textAlign: "center",
								borderRadius: "15px",
								width: 250,
								height: 350,
								boxShadow: 4,
							}}
							p={2}>
							<Stack
								sx={{
									borderRadius: "50%",
									width: 100,
									height: 100,
									border: "2px dashed grey",
								}}
								justifyContent='center'
								alignItems='center'>
								<AddIcon
									sx={{
										objectFit: "contain",
										maxWidth: "85%",
										maxHeight: "85%",
									}}
								/>
							</Stack>
							<Typography>Join Groups</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Box>
		);
	} else if (!loading && currentUser) {
		return (
			<Grid onClick={() => navigate("/joinclubs")}>
				<Stack
					spacing={2}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						textAlign: "center",
						borderRadius: "15px",
						width: 250,
						height: 250,
						boxShadow: 4,
					}}
					p={2}>
					<Stack
						sx={{
							borderRadius: "50%",
							width: 100,
							height: 100,
							border: "2px dashed grey",
						}}
						justifyContent='center'
						alignItems='center'>
						<AddIcon
							sx={{ objectFit: "contain", maxWidth: "85%", maxHeight: "85%" }}
						/>
					</Stack>
					<Typography>Join Club</Typography>
				</Stack>
			</Grid>
		);
	} else {
		return <Loading></Loading>;
	}
};

export default Clubs;
