import {
	Box,
	Button,
	ButtonBase,
	Stack,
	Typography,
	TextField,
	MenuItem,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import OptionCard from "./OptionCard";
import { useNavigate } from "react-router-dom";
import { createEmptyPost, getCompanies } from "../../Utilities/firebase";
import { useAuth } from "../../Contexts/AuthContext";
import ProgressFooter from "./ProgressFooter";

const CompanyDetails = () => {
	const [selected, setSelected] = useState(-1);

	const [companyId, setCompanyId] = useState(null);

	const { currentUser } = useAuth();
	const navigate = useNavigate();

	//TODO Define Going back and saving a Draft
	const navigateBack = () => {};
	const saveDraft = () => {};

	const navigateNext = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const companyData = {
			logo: data.get("logo"),
			name: data.get("name"),
		};
		if (companyData) {
			navigate("/postOpportunity/type", {
				state: {
					companyId: null,
					companyData: companyData,
					progress: 25,
					buffer: 50,
				},
			});
		}
	};

	return (
		<Box
			sx={{ mx: { xs: "0px", lg: "120px" } }}
			onSubmit={navigateNext}
			autoComplete='off'
			component='form'>
			<Stack
				sx={{
					mx: { xs: "120px", lg: "120px" },
					marginTop: "25px",
					rowGap: "50px",
				}}>
				<div>
					<Typography variant='h5' fontWeight={500}>
						Post an Opportunity
					</Typography>
					<Typography variant='body1'>1a. Add Company Details</Typography>
				</div>
				<Box
					sx={{
						display: "flex",
						rowGap: "50px",
						alignItems: "center",
						flexDirection: "column",
						justifyContent: "center",
					}}>
					<Stack
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "40px",
							justifyContent: "center",
							flexWrap: "wrap",
						}}>
						<TextField
							id='name'
							name='name'
							label='Company Name'
							sx={{
								minWidth: 400,
							}}
							required></TextField>
						<TextField
							id='logo'
							name='logo'
							label='Company Logo'
							helperText='Paste the image address of a square version of the logo'
							sx={{
								minWidth: 400,
							}}
							required></TextField>
					</Stack>
					<Stack
						alignItems='center'
						justifyContent='center'
						display='flex'
						spacing={2}>
						<Button
							type='submit'
							variant='contained'
							style={{
								maxWidth: "120px",
								maxHeight: "60px",
								minWidth: "120px",
								minHeight: "60px",
							}}>
							Next
						</Button>
					</Stack>
				</Box>
			</Stack>
			<ProgressFooter
				progress={0}
				buffer={25}
				next={navigateNext}
				back={navigateBack}
				save={saveDraft}
			/>
		</Box>
	);
};

export default CompanyDetails;
