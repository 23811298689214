import * as React from "react";
import { Typography, Grid, Stack } from "@mui/material";

// const drawerWidth = 240;

const JoinClubCard = ({ club, handleSelect }) => {
	const [isActive, setActive] = React.useState(false);

	const handleSelectClub = () => {
		setActive(!isActive);
		handleSelect();
	};

	return (
		<Grid item onClick={() => handleSelectClub(club.uid)}>
			<Stack
				spacing={2}
				justifyContent='center'
				alignItems='center'
				textAlign="center"
				sx={{
					borderRadius: "15px",
					width: 250,
					height: 350,
					boxShadow: 4,
					border: 3,
					borderColor: isActive === true ? "primary.main" : "white",
				}}
				p={2}
			>
				<img
					src={club.logo}
					style={{ objectFit: "contain", maxWidth: "70%", maxHeight: "70%" }}
					alt={`${club.text} Logo`}
				/>
				<Typography sx={{fontWeight: 'bold'}}>{club.name}</Typography>
			</Stack>
		</Grid>
	);
};

export default JoinClubCard;
