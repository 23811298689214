import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Paper,
	Checkbox,
	IconButton,
	Tooltip,
} from "@mui/material";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";

import ClubExecMenu from "./ClubExecMenu";

function createData(firstName, lastName, email, uid) {
	return {
		firstName,
		lastName,
		email,
		uid,
		removed: false,
	};
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: "firstName",
		numeric: false,
		disablePadding: false,
		label: "First Name",
	},
	{
		id: "lastName",
		numeric: false,
		disablePadding: false,
		label: "Last Name",
	},
	{
		id: "email",
		numeric: false,
		disablePadding: false,
		label: "Email",
	},
];

function EnhancedTableHead(props) {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding='checkbox'>
					<Checkbox
						color='primary'
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all users",
						}}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
	const { numSelected, selectedUIDs, clubRef, handleRemoveRows } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: "1 1 100%" }}
					color='inherit'
					variant='subtitle1'
					component='div'>
					{numSelected} selected
				</Typography>
			) : null}

			{numSelected > 0 ? (
				<Tooltip title='Menu'>
					<ClubExecMenu
						selectedUIDs={selectedUIDs}
						clubRef={clubRef}
						handleRemoveRows={handleRemoveRows}
					/>
				</Tooltip>
			) : (
				<Tooltip title='Filter list'>
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ exec, clubRef, handleChangeExec }) {
	const [load, setLoad] = React.useState(false);
	const [rows, setRows] = React.useState(exec);
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("firstName");
	const [selected, setSelected] = React.useState([]);
	const [selectedUIDs, setSelectedUIDs] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	React.useEffect(() => {
		setLoad(false);
		let reqArray = [];
		// TODO
		// Possibly sus solution to bug - Navaneet (claims it's legit) and Justin
		// Need to enhance loading of page to wait for exec to load
		if (exec) {
			for (let requester of exec) {
				let data = createData(
					requester.first_name,
					requester.last_name,
					requester.email,
					requester.user_id
				);
				reqArray.push(data);
			}
		}
		setRows(reqArray);
		setLoad(true);
	}, [exec]);
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.firstName);
			const newSelectedUIDs = rows.map((n) => n.uid);
			setSelectedUIDs(newSelectedUIDs);
			setSelected(newSelected);
			return;
		}
		setSelectedUIDs([]);
		setSelected([]);
	};

	const handleClick = (event, firstName, uid) => {
		const properClick =
			event.target.id.indexOf("enhanced-table-checkbox") === 0 ||
			event.target.id.indexOf("checkbox") === 0;
		const selectedIndex = selected.indexOf(firstName);
		const selectedUIDIndex = selectedUIDs.indexOf(uid);
		let newSelected = [];
		let newSelectedUIDs = [];
		if (!properClick) {
			return;
		}

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, firstName);
			newSelectedUIDs = newSelectedUIDs.concat(selectedUIDs, uid);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
			newSelectedUIDs = newSelectedUIDs.concat(selectedUIDs.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
			newSelectedUIDs = newSelectedUIDs.concat(selectedUIDs.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
			newSelectedUIDs = newSelectedUIDs.concat(
				selectedUIDs.slice(0, selectedUIDIndex),
				selectedUIDs.slice(selectedUIDIndex + 1)
			);
		}
		setSelectedUIDs(newSelectedUIDs);
		setSelected(newSelected);
	};

	const handleRemoveRows = (uidArr) => {
		let newExecs = [];
		for (let user of exec) {
			const uidArrIndex = uidArr.indexOf(user.user_id);
			if (uidArrIndex === -1) {
				newExecs.push(user);
			}
		}

		handleChangeExec(newExecs);
		setSelected([]);
		setSelectedUIDs([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (firstName) => selected.indexOf(firstName) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
	if (load) {
		return (
			<Box sx={{ width: "100%" }}>
				<Paper sx={{ width: "100%", mb: 2 }}>
					<EnhancedTableToolbar
						numSelected={selected.length}
						selectedUIDs={selectedUIDs}
						clubRef={clubRef}
						handleRemoveRows={handleRemoveRows}
					/>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby='tableTitle'
							size='medium'
							stickyHeader>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
							/>
							<TableBody>
								{stableSort(rows, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isItemSelected = isSelected(row.firstName);
										const labelId = `enhanced-table-checkbox-${index}`;
										const checkboxID = `checkbox-${index}`;

										return (
											<TableRow
												hover
												onClick={(event) =>
													handleClick(event, row.firstName, row.uid)
												}
												role='checkbox'
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.name}
												selected={isItemSelected}>
												<TableCell padding='checkbox'>
													<Checkbox
														id={checkboxID}
														color='primary'
														checked={isItemSelected}
														inputProps={{
															"aria-labelledby": labelId,
														}}
													/>
												</TableCell>
												<TableCell
													component='th'
													id={labelId}
													scope='row'
													padding='normal'>
													{row.firstName}
												</TableCell>
												<TableCell
													component='th'
													id={labelId}
													scope='row'
													padding='normal'>
													{row.lastName}
												</TableCell>
												<TableCell
													component='th'
													id={labelId}
													scope='row'
													padding='normal'>
													{row.email}
												</TableCell>
												<TableCell padding='none'>
													<ClubExecMenu
														selectedUIDs={[row.uid]}
														clubRef={clubRef}
														handleRemoveRows={handleRemoveRows}
													/>
												</TableCell>
											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * emptyRows,
										}}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, { value: -1, label: "All" }]}
						component='div'
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>
		);
	}
}
