import React, { useEffect } from 'react';
import { Box, List, Stack, ListItem, ListItemText, Typography } from "@mui/material";

export var version = '1.0';

const InboundPrivacyPolicy = () => {

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if(element)
            element.scrollIntoView({ behavior: 'smooth' });
      };
    
      useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          scrollToSection(hash.substring(1));
        }
      }, []);

  return (
    <Box sx={{
      paddingX: 10
    }}>
      <Typography variant="h4" sx={{ marginY: 2 }}>
      Inbound Privacy Policy      </Typography>
      <Typography variant="h5" sx={{ marginBottom: 4}}>
      Version {version} - Last Updated 10/26/2022      </Typography>


      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
            Welcome to Inbound Careers!
        </Typography>
        <Typography >
        We are committed to protecting the privacy of the personal information you give to us when using our Platform and we value transparency as to the information we collect. Our “Privacy Policy” (see below) outlines what information we collect and how it is used.
We invite you to carefully read our Privacy Policy and to contact us with any questions about the collection and processing of your Personal Data at the following e-mail address: han@joininbound.com. Please note that the Policy may change over time. You as a user will be notified when significant changes to your rights are made via the contact information you provide.
        </Typography>
        </Stack>

        <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6">
        Table of contents
        </Typography>
        <Typography >
        To access the features of Inbound Careers you must create an account, either as a student or a student organization.
        </Typography>
        <List>
      <ListItem button onClick={() => scrollToSection('how-to-use')}>
          <ListItemText primary="1. How to use our site" />
    </ListItem>
      <ListItem button onClick={() => scrollToSection('data-collection')}>
        <ListItemText primary="2. What data we collect" />
      </ListItem>
      <ListItem button onClick={() => scrollToSection('data-use')}>
        <ListItemText primary="3. How do we use your information" />
      </ListItem>
      <ListItem button onClick={() => scrollToSection('age-restrictions')}>
        <ListItemText primary="4. Age restrictions" />
      </ListItem>
      <ListItem button onClick={() => scrollToSection('california-residents')}>
        <ListItemText primary="5. California residents" />
      </ListItem>
      <ListItem button onClick={() => scrollToSection('waiver')}>
        <ListItemText primary="6. Class Action Waiver" />
      </ListItem>
      <ListItem button onClick={() => scrollToSection('liability')}>
        <ListItemText primary="7. Liability Disclaimer" />
      </ListItem>
      <ListItem button onClick={() => scrollToSection('termination')}>
        <ListItemText primary="8. Termination/Access Restriction" />
      </ListItem>
    </List>
        </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6" id="how-to-use">
        1. How to use our site 
        </Typography>
        <Typography >
        To access the features of Inbound Careers you must create an account, either as a student or a student organization.
        </Typography>
        </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6" id="data-collection">
        2. What data we collect
        </Typography>
        <Stack spacing={{ xs: 3, sm: 3, md: 3}}>

        <Typography >
        For each of our individual users and groups, we collect personal and non personal data.
        </Typography>
        <Typography >
We collect personal information such as your name, email address, gender, university, major, phone number, and graduation date to identify your account. All individuals and student groups are in control of what personal information they provide to Inbound Careers during the sign-up process and each time you update your personal or group profile. Relevant information includes but is not limited to career experiences, skills, university affiliations, resumes, transcripts etc. Profile information can be updated either through uploading documents, or updating profiles.      
        </Typography>
        </Stack>
    </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }} >
        <Typography variant="h6" id="data-use">
        3. How do we use your information
                </Typography>
        <Typography >
        We use and store personally identifying information to maximize your experience with Inbound Careers.   
        </Typography>

        <Stack spacing={{ xs: 1, sm: 1, md: 1}} sx ={{
            paddingTop: 2
        }}>
            <Typography variant='h6' fontWeight={400} fontStyle={'italic'}>
            Your Profile            </Typography>
            <Typography >
            We provide you with a public-facing profile to connect student groups and individual students with employers. All users can decide whether to make their profile information publicly available to other Inbound Careers users through their profile. All information published publicly can be accessed by anyone with an Inbound Careers account. It can be used to make recruitment decisions, extend opportunities, and inform hiring practices.            </Typography>
        </Stack>


        <Stack spacing={{ xs: 1, sm: 1, md: 1}} sx ={{
            paddingTop: 2
        }}>
            <Typography variant='h6' fontWeight={400} fontStyle={'italic'}>
            Other Information            </Typography>
            <Typography >
            Data you generate while using our site may be used to improve our site and its features. It may inform what employment opportunities you see on our site and what communications you receive.            </Typography>
        </Stack>


        <Stack spacing={{ xs: 1, sm: 1, md: 1}} sx ={{
            paddingTop: 2
        }}>
            <Typography variant='h6' fontWeight={400} fontStyle={'italic'}>
            Contact Information
            </Typography>
            <Typography >
            Inbound Careers uses contact information such as an email address or phone number to communicate important information about our site, deliver employment opportunities, and advertise our features. We do not sell your contact information to third parties.
            </Typography>
            <Typography >
            To opt-out of email communications from Inbound Careers, please click “unsubscribe”, found at the bottom of each email and follow the instructions provided. To opt out of mobile alerts, respond “STOP”.            </Typography>

        </Stack>


       </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6" id="age-restrictions">
        4. Age restrictions       </Typography>
          <Stack spacing={{ xs: 3, sm: 3, md: 3}}>
        <Typography>
        Inbound Careers does not sell your personal data to third parties, including your email address, phone number, and full name. The information users choose to make public on their profiles is available to other users.          </Typography >
          <Typography >
          We only share your personal data with your consent, such as when you post to your public profile. We may also share personal data with third party contractors who provide services to keep our site running, such as cloud service providers, credit card processing services, email management, and security services. These contractors are required to protect all personal-identifying information they receive.          </Typography >
          <Typography >
          If required by law, such as when necessary in compliance with data privacy laws or as part of a subpoena or warrant, we may share your information with law enforcement bodies. Unless disclosure is prohibited, we will do our best to notify you that such a process is occurring.         
          </Typography >
          <Typography >
          We may also share user information with potential purchasers or investors during a reorganization, merger, sale, or bankruptcy. Any company that gains access to your data via merger, acquisition, or investment will be subject to the same privacy policy in place at the time of the transaction.          </Typography >
           </Stack>
      </Stack>


      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6" id="california-residents">
        5. California residents        </Typography>
        <Stack spacing={{ xs: 3, sm: 3, md: 3}}>
        <Typography>
        Information you choose to delete from your personal, group, or employer profile will be removed publicly, but may still be used by Inbound Careers to personalize your experience.          </Typography >
          <Typography >
          You can deactivate, make private, or delete your account. However, Inbound Careers may not be able to remove all personal information it has collected about you.         </Typography >
           </Stack>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6" id="waiver">
        6. Class Action Waiver        </Typography>
        <Typography >
        Your personally-identifying information is protected via industry standard methods. We take data privacy seriously at Inbound Careers and take precaution against potential breaches. However, like all internet sites, we cannot guarantee against breeches or data loss events. We can guarantee that all such events will be investigated and those affected will be notified, when appropriate.
        </Typography>
              </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6" id="liability">
        7. Liability Disclaimer       </Typography>
        <Typography >
        All users must be 16 years or older to use our site, as included in our Terms of Service, and we do not collect any information intentionally from anyone under the age of 13 years old. If we become aware that we are in possession of such data, we will take all reasonable steps to remove it.        </Typography>
      </Stack>

      <Stack spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginBottom: 3 }}>
        <Typography variant="h6" id="termination">
        8. Termination/Access Restriction        </Typography>

          <Stack spacing={3}>
        <Typography >
        If you are a California resident, note that Inbound Careers complies with the CCPA in handling your personal data. This Privacy Policy serves as a notification of what data is collected in conjunction with your usage of the account. You will be notified via email in the case that our data collection and/or storage policy changes.          </Typography>

          <Typography >
          Upon request, Inbound Careers is required by law to delete all personal information collected about you. To do so, contact <a href={`mailto:han@joininbound.com`}>han@joininbound.com</a>.          </Typography>

          <Typography>
          We will not and do not discriminate against you for choosing the exercise any of the rights ascribed to you under the CCPA. Generally, businesses cannot discriminate against you for exercising your rights under the CCPA. For more information about your rights as a California web user, refer here.
          </Typography>
                    </Stack>
      </Stack>
    </Box>
  )
}

export default InboundPrivacyPolicy;
