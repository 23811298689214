import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Stack, Box } from "@mui/material";

import UserSettings from "../Settings/Settings";
import Navbar from "../Common/Navbar";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../Utilities/firebase";
import FeedbackForm from "../Common/FeedbackForm";

const SettingsLayout = () => {
	const [user, setUser] = useState(null);

	const link = window.location.pathname;
	const loginAttempt = link === "/signin" || link === "/signup";
	let accessGranted = user !== null || loginAttempt;
	const navigate = useNavigate();
	useEffect(() => {
		const fetchData = async () => {
			const user = await getCurrentUser();
			if (user == null && !loginAttempt) {
				navigate("/signin");
			}
			setUser(user);
		};
		fetchData();
	}, []);
	if (accessGranted) {
		return (
			<div className='container'>
				<div style={{ height: 64 }}></div>
				<Stack spacing={2}>
					<Navbar />
					<Stack direction='row' spacing={2}>
						<UserSettings />
						<Stack width='80%' spacing={2}>
							<Outlet />
							<FeedbackForm />
						</Stack>
					</Stack>
				</Stack>
			</div>
		);
	}
};

export default SettingsLayout;
