import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { red } from "@mui/material/colors";

const RejectButton = ({ handleReject }) => {
	return (
		<IconButton
			sx={{
				borderRadius: "50%",
				border: 2,
				borderColor: red[900],
			}}
			ml={1}
			mr={1}
			style={{ maxWidth: "30px", maxHeight: "30px" }}
			onClick={handleReject}>
			<CloseIcon style={{ color: red[900] }} />
		</IconButton>
	);
};

export default RejectButton;
