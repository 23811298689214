import * as React from "react";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Link as RouterLink, Route } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
	signUp,
	getCurrentUser,
	getAllUniversities,
} from "../../Utilities/firebase";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { universityOptions } from "../Settings/Profile.js";
import { useAuth } from "../../Contexts/AuthContext";

function SignUp() {
	const navigate = useNavigate();
	const { currentUser } = useAuth();
	const [privacyPolicy, setPrivacyPolicy] = useState(false);
	const [termsOfService, setTermsOfService] = useState(false);
	const [universityOptions, setUniversities] = useState();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		const email = data.get("email");
		const password = data.get("password");
		const lastName = data.get("lastName");
		const firstName = data.get("firstName");
		const universityID = data.get("university");
		if (!(email && password && lastName && firstName && universityID)) {
			alert("Please fill out all fields.");
			return;
		}
		await signUp(email, password, lastName, firstName, universityID);
	};
	useEffect(() => {
		async function fetch() {
			const schools = await getAllUniversities();
			setUniversities(schools);
		}
		fetch();
	}, []);

	useEffect(() => {
		if (currentUser) {
			alert("Your account was created succesfully!");
			navigate("/posts");
		}
	}, [currentUser]);

	if (universityOptions) {
		return (
			<Container component='main' maxWidth='xs'>
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component='h1' variant='h5'>
						Sign up
					</Typography>
					<Box
						component='form'
						noValidate
						onSubmit={(e) => {
							handleSubmit(e);
						}}
						sx={{ mt: 3 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									required
									fullWidth
									name='firstName'
									id='firstName'
									label='First Name'
									autoComplete='given-name'
									autoFocus
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									required
									fullWidth
									name='lastName'
									id='lastName'
									label='Last Name'
									autoComplete='family-name'
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name='email'
									id='email'
									label='Email Address'
									autoComplete='email'
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name='password'
									id='password'
									label='Password'
									type='password'
									autoComplete='new-password'
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									select
									id='university'
									name='university'
									label='University'
									defaultValue={""}>
									{universityOptions.map((option) => (
										<MenuItem key={option.data.name} value={option.id}>
											{option.data.name}
										</MenuItem>
									))}
								</TextField>
							</Grid>
						</Grid>
						<div style={{ fontSize: 16, paddingTop: 24, textAlign: "center" }}>
							<span>Open and acknowledge Inbound's </span>
							<Link
								component={RouterLink}
								to='/termsofservice'
								target='_blank'
								onClick={() => setTermsOfService(true)}>
								Terms Of Service
							</Link>
							<span> and </span>
							<Link
								component={RouterLink}
								to='/privacypolicy'
								target='_blank'
								onClick={() => setPrivacyPolicy(true)}>
								Privacy Policy
							</Link>
							<span> to sign up.</span>
						</div>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2 }}
							disabled={!(privacyPolicy && termsOfService)}>
							Sign Up
						</Button>
						<div style={{ fontSize: 10, color: "gray", textAlign: "center" }}>
							{privacyPolicy && termsOfService ? (
								<span></span>
							) : (
								<div style={{ paddingBottom: 16 }}>
									<span>
										By signing up, you agree to Inbound's Terms of Service and
										Privacy Policy.
									</span>
								</div>
							)}
						</div>
						<Grid container justifyContent='flex-end'>
							<Grid item>
								<Link component={RouterLink} to='/signin' variant='body2'>
									Already have an account? Sign in
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		);
	}
}

export default SignUp;
