import React from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import animationData from "./Lotties/loading.json";
import Lottie from "react-lottie";

export default function PlainLoading() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            gap="10px"
            display={"flex"}
            alignItems="center"
            flexDirection={"column"}
          >
            <Box marginTop={-5}>
              <Lottie options={defaultOptions} height={200} width={200}></Lottie>
            </Box>
          </Box>
        </Box>
      );
        }