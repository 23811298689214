import React from "react";
import { Stack, Box } from "@mui/material";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import Hero from "./Hero";
import SignUp from "./SignUp";
import Footer from "../Common/Footer";
import OpportunitiesImage from "../Landing/viewposts.png";
import RelationshipImage from "../Landing/discussionpost.png";
import Video from "../Landing/cardiacrehab.mp4"
import SummariesImage from "../Landing/message.png";
import Banner from "./Banner";

const Landing = () => {
	return (
		<Stack
			spacing={{ xs: 1, sm: 3, md: 10 }}
			sx={{
				overflow: "hidden",

			}}>
      		<Banner message="We just launched! Sign up to get started with our platform today" />
			<Hero></Hero>
			<Stack sx = {{alignItems: 'center', just: "center", width: '100%'}}>
				<Box width={{ xs: 400, sm: 600, md: 1200 }}
				sx = {{
						justifyContent: 'center',
						alignItems: 'center',
						display: "flex",
						
				}}>
					{/*
					<video controls width='100%'>
						<source src={Video} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					*/}
				</Box>
			</Stack>
			<Stack spacing={{ xs: 1, sm: 3, md: 10 }} sx={{}}>
				<LeftSection
					mainText={[
						"View",
						"professional opportunities",
						"posted directly to",
						"your groups",
						"by employers and student leaders",
					]}
					bodyText='Gain access to full-time roles, internships, corporate events, and announcements'
					image={OpportunitiesImage}></LeftSection>
				{/*
				<RightSection
					mainText={[
						"Develop",
						"individual relationships",
						"with recruiters through comments",
					]}
					bodyText='Post comments on opportunities shared by recruiters to ask questions'
					image={RelationshipImage}></RightSection>
				<LeftSection
					mainText={["Receive", "weekly summaries", "directly into your inbox"]}
					bodyText='Customize the frequency and types of opportunities that are sent your way'
					image={SummariesImage}></LeftSection>
				*/}
			</Stack>
			{/*<SignUp></SignUp>*/}
			<Footer></Footer>
		</Stack>
	);
};

export default Landing;
