import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import HoverMenu from "./HoverMenu";
import PostedToIcons from "./PostedToIcons";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function shortenLocations(locationArray) {
	let arrLength = locationArray.length;
	let leftover = arrLength - 1;
	let text = `${locationArray[0]} + ${leftover} more`;
	return <HoverMenu text={text} options={locationArray} />;
}

function timeSince(date) {
	var seconds = Math.floor((new Date() - date) / 1000);
	var interval = seconds / 31536000;
	if (interval > 1) {
		return (
			Math.floor(interval) + " year" + (Math.floor(interval) > 1 ? "s" : "")
		);
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return (
			Math.floor(interval) + " month" + (Math.floor(interval) > 1 ? "s" : "")
		);
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return (
			Math.floor(interval) + " day" + (Math.floor(interval) > 1 ? "s" : "")
		);
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return (
			Math.floor(interval) + " hour" + (Math.floor(interval) > 1 ? "s" : "")
		);
	}
	interval = seconds / 60;
	if (interval > 1) {
		return (
			Math.floor(interval) + " minute" + (Math.floor(interval) > 1 ? "s" : "")
		);
	}
	return (
		Math.floor(seconds) + " second" + (Math.floor(interval) > 1 ? "s" : "")
	);
}

const Img = styled("img")({
	margin: "auto",
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
});

export default function MobileCard({ listing, open, handleClose }) {
	const locations = shortenLocations(listing.locations);
	const timeAgo = timeSince(listing.date_created);
	const deadline = listing.date_deadline.toLocaleString();

	return (
		<div>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				sx={{ display: { xs: "block", md: "none" } }}>
				<AppBar sx={{ position: "sticky", zIndex: 1401, top: "-64px" }}>
					<Toolbar>
						<IconButton
							edge='start'
							color='inherit'
							onClick={handleClose}
							aria-label='close'>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
							{listing.title}
						</Typography>
						<Button
							variant='outlined'
							color='white'
							href={listing.link}
							target='_blank'>
							Apply
						</Button>
					</Toolbar>
				</AppBar>
				<Box sx={{ m: 4 }}>
					<Box sx={{ display: "flex", mb: 2 }}>
						<Box component='span'>
							<Img
								alt={listing}
								src={listing.image}
								sx={{ width: 72, height: 72, borderRadius: "12px" }}
							/>
						</Box>
						<Box
							component='span'
							textAlign={"left"}
							sx={{ flexGrow: 1, ml: "20px" }}>
							<Typography variant='h6' fontWeight={"regular"}>
								{listing.company}
							</Typography>
							{locations}
						</Box>
					</Box>
					<Box component='span'>
						<Typography variant='body1' fontWeight={"medium"}>
							{`Posted ${timeAgo} ago`}
						</Typography>
					</Box>
					<Box textAlign={"left"} sx={{ mb: 2 }}>
						<Typography
							textAlign={"left"}
							gutterBottom
							variant='body2'
							sx={{ fontSize: "1rem" }}>
							Application Deadline: <b>{deadline}</b>
						</Typography>
					</Box>
					<Box textAlign={"left"} sx={{ mb: 2 }}>
						<Typography variant='h6' fontWeight={"medium"}>
							Job Description
						</Typography>
						<Typography variant='body2'>{listing.description}</Typography>
					</Box>
					<PostedToIcons clubs={listing.clubs} />
				</Box>
			</Dialog>
		</div>
	);
}
