import { Box, Typography } from "@mui/material";
import React from "react";

const NoPosts = () => {
	return (
		<Box
			sx={{
				p: "40px",
				m: "10px",
				borderRadius: "40px",
			}}>
			<Box sx={{ display: "flex", mb: 2, flexDirection: "column" }}>
				<Typography variant='h3'>
					Your next job is <b><span style={{ color: "rgb(72, 47, 215)"}}>Inbound</span></b>!
				</Typography>
				<Typography  sx={{ color: "gray"}} variant='h5'>
					Head to Settings and <b><span style={{ color: "rgb(72, 47, 215)"}}>join groups</span></b> to access opportunities from your favorite companies.
				</Typography>
			</Box>
		</Box>
	);
};

export default NoPosts;
