import React from "react";
import { Box, Button, LinearProgress, Paper, Typography } from "@mui/material";

function LinearProgressWithLabel({ value, valueBuffer }) {
	return (
		<Box sx={{ display: "flex", flexGrow: "1", alignItems: "center" }}>
			<Box sx={{ width: "100%", mr: 1 }}>
				<LinearProgress
					variant='buffer'
					value={value}
					valueBuffer={valueBuffer}
				/>
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography
					width={"max-content"}
					variant='body2'
					color='text.secondary'>
					{`${value}% Complete`}
				</Typography>
			</Box>
		</Box>
	);
}

const ProgressFooter = ({
	progress,
	buffer,
	next,
	back,
	save,
	nextDisabled = false,
	submitNeeded = false,
}) => {
	return (
		<Paper
			elevation={4}
			sx={{
				position: "fixed",
				width: "100%",
				padding: "1.75em",
				left: "0",
				bottom: "0",
				zIndex: "10",
			}}>
			<Box display={"flex"} gap={"2rem"}>
				<Button
					variant='outlined'
					onClick={back}
					disabled={progress - progress == 0}>
					Back
				</Button>
				<LinearProgressWithLabel value={progress} valueBuffer={buffer} />
				{submitNeeded ? (
					<Button disabled={nextDisabled} type='submit' variant='contained'>
						{buffer == 100 ? "Submit" : "Next"}
					</Button>
				) : (
					<Button disabled={nextDisabled} onClick={next} variant='contained'>
						{buffer == 100 ? "Submit" : "Next"}
					</Button>
				)}
			</Box>
		</Paper>
	);
};

export default ProgressFooter;
