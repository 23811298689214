import * as React from "react";
import {
	AppBar,
	Box,
	Toolbar,
	IconButton,
	Typography,
	Menu,
	Container,
	Stack,
	MenuItem,
	CssBaseline,
	Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../images/Logo.svg";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, signOutUser } from "../../Utilities/firebase";
import { useState, useEffect } from "react";
import { useAuth } from "../../Contexts/AuthContext";

const Navbar = () => {
	const [userStatus, setUserStatus] = useState(false);
	const [userExec, setUserExec] = useState(false);

	const { currentUser } = useAuth();

	useEffect(() => {
		if (currentUser) {
			setUserStatus(true);
			if (currentUser.exec_for.length) {
				setUserExec(true);
			}
		} else {
			setUserStatus(false);
		}
	}, [currentUser]);

	const [anchorElNav, setAnchorElNav] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const navigate = useNavigate();

	const handleChangeUserStatus = async () => {
		if (userStatus) {
			await signOutUser();
			navigate("/signin");
		} else {
			navigate("/signin");
		}
	};

	const handleNavigateSettings = () => {
		navigate("/settings");
	};

	const handleNavigatePosts = () => {
		if (window.location.href.indexOf("posts") == -1) {
			navigate("/posts");
		}
	};

	const handleNavigatePostOpportunity = () => {
		if (userStatus) {
			navigate("/postopportunity");
		} else {
			navigate("/");
		}
	};

	const handleNavigateHome = () => {
		if (userStatus) {
			navigate("/posts");
		} else {
			navigate("/");
		}
	};

	const pages = ["Settings", userStatus];

	return (
		<div className='Navbar' id='navbar'>
			<CssBaseline />
			<AppBar
				position='fixed'
				color='white'
				m={0}
				sx={{ zIndex: 1400, height: 64 }}>
				<Container maxWidth='xl'>
					<Toolbar disableGutters>
						<Box sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}>
							<img src={Logo} width={40} alt='Logo' />
						</Box>
						<Typography
							variant='h5'
							noWrap
							component='a'
							onClick={handleNavigateHome}
							sx={{
								mr: 2,
								display: { xs: "none", md: "flex" },
								fontFamily: "Poppins",
								fontWeight: 500,
								color: "inherit",
								textDecoration: "none",
								cursor: "pointer",
							}}>
							Inbound
						</Typography>
						<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
							<IconButton
								size='large'
								aria-label='account of current user'
								aria-controls='menu-appbar'
								aria-haspopup='true'
								onClick={handleOpenNavMenu}
								color='inherit'>
								<MenuIcon />
							</IconButton>
							<Menu
								id='menu-appbar'
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", md: "none" },
								}}>
								{pages.map((page) => (
									<MenuItem key={page} onClick={handleCloseNavMenu}>
										<Typography textAlign='center'>{page}</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>
						<Box sx={{ display: { xs: "flex", md: "none" }, mr: 2 }}>
							<img src={Logo} width={40} alt='Logo' />
						</Box>
						<Typography
							variant='h5'
							noWrap
							component='a'
							onClick={handleNavigateHome}
							sx={{
								mr: 2,
								display: { xs: "flex", md: "none" },
								flexGrow: 1,
								fontFamily: "Poppins",
								fontWeight: 500,
								color: "inherit",
								textDecoration: "none",
							}}>
							Inbound
						</Typography>
						<Box
							sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

						<Stack
							sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}
							direction='row'
							spacing={2}>
							<Box
								display='flex'
								sx={{
									alignItems: "center",
									justifyItems: "center",
								}}>
								{userStatus && (
									<Typography
										variant='body3'
										noWrap
										component='a'
										onClick={handleNavigatePosts}
										sx={{
											mr: 2,
											display: "flex",
											fontFamily: "Poppins",
											fontWeight: 500,
											color: "inherit",
											textDecoration: "none",
											flexGrow: 1,
											cursor: "pointer",
										}}>
										Posts
									</Typography>
								)}
								{userStatus && (
									<Typography
										variant='body3'
										noWrap
										component='a'
										onClick={handleNavigateSettings}
										sx={{
											mr: 2,
											display: "flex",
											fontFamily: "Poppins",
											fontWeight: 500,
											color: "inherit",
											textDecoration: "none",
											flexGrow: 1,
											cursor: "pointer",
										}}>
										Settings
									</Typography>
								)}
								<Typography
									variant='body3'
									noWrap
									component='a'
									onClick={handleChangeUserStatus}
									sx={{
										mr: 2,
										display: "flex",
										fontFamily: "Poppins",
										fontWeight: 500,
										color: "inherit",
										textDecoration: "none",
										flexGrow: 1,
										cursor: "pointer",
									}}>
									{userStatus ? "Sign Out" : "Sign In"}
								</Typography>
								{userStatus && (
									<Button
										variant="contained"
										onClick={handleNavigatePostOpportunity}
										sx={{
											display: userExec ? "flex" : "none",
										}}
									>
										Post an Opportunity
									</Button>
								)}
							</Box>
						</Stack>
					</Toolbar>
				</Container>
			</AppBar>
		</div>
	);
};
export default Navbar;
