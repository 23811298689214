import * as React from "react";
import { Typography, Grid, Fab, Box } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import JoinClubCard from "./JoinClubCard.js";

import {
	getClubRef,
	getClubs,
	getCurrentUser,
	getUser,
	requestToClub,
} from "../../../../Utilities/firebase.js";
import { useAuth } from "../../../../Contexts/AuthContext";
import Loading from "../../../Loading/Loading";

const drawerWidth = 240;

const JoinClubs = () => {
	const [loading, setLoading] = React.useState(true);
	const [clubs, setClubs] = React.useState(null);
	const [activeClubIds, setActiveClubIds] = React.useState([]);
	const { currentUser } = useAuth();
	React.useEffect(() => {
		const fetchData = async () => {
			let clubData = await getClubs(currentUser.university);
			clubData = clubData.filter((obj) => {
				const clubIDs = currentUser.clubs.map((clubref) => {
					return clubref.id;
				});
				return !clubIDs.includes(obj[1]);
			});
			let clubs = [];
			clubData.forEach((clubArr) => {
				const club = { ...clubArr[0], uid: clubArr[1] };
				clubs.push(club);
			});
			setClubs(clubs);
			setLoading(false);
		};
		fetchData();
	}, []);

	// const navigate = useNavigate();
	const handleSendRequests = async (clubIdList) => {
		setLoading(true);
		const user = await getCurrentUser();
		const userData = await getUser(user.uid);
		const schoolId = userData.university;
		for (const clubId of clubIdList) {
			const clubRef = await getClubRef(schoolId, clubId);
			await requestToClub(clubRef, user.uid);
		}
		setLoading(false);
	};

	const handleSelectClub = (clubId) => {
		const activeIdsCopy = [...activeClubIds];
		const index = activeIdsCopy.indexOf(clubId);
		if (index >= 0) {
			activeIdsCopy.splice(index, 1);
			setActiveClubIds(activeIdsCopy);
		} else {
			activeIdsCopy.push(clubId);
			setActiveClubIds(activeIdsCopy);
		}
	};
	if (!loading && clubs) {
		return (
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					p: 1,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}>
				<Typography variant='h3' mb={2}>
					Join Groups
				</Typography>
				<Typography variant='h5' mb={2}>
					Select your groups and request to join
				</Typography>
				<Typography
					variant='body1'
					sx={{
						fontStyle: "italic",
					}}
					mb={2}>
					Please only request to join groups that you are already a member of.
				</Typography>
				<Fab
					onClick={() => handleSendRequests(activeClubIds)}
					variant='extended'
					sx={{
						margin: 0,
						top: "auto",
						right: 40,
						bottom: 40,
						left: "auto",
						position: "fixed",
						zIndex: 1,
					}}>
					<GroupAddIcon sx={{ mr: 1 }} />
					Request to Join
				</Fab>
				<Grid
					container
					spacing={{ xs: 2, md: 3 }}
					columns={{ xs: 4, sm: 8, md: 12 }}>
					{clubs.map((club) => (
						<JoinClubCard
							key={club.uid}
							club={club}
							handleSelect={() => handleSelectClub(club.uid)}
						/>
					))}
				</Grid>
			</Box>
		);
	} else {
		return <Loading></Loading>;
	}
};

export default JoinClubs;
