import { Stack, Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import React from "react";

const NotFound = () => {
	return (
		<Stack
			sx={{
				gap: "10px",
				margin: "3rem",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<SentimentVeryDissatisfiedIcon sx={{ fontSize: 200 }} />
			<Typography>
				<b>Error 404:</b> Web Page Not Found
			</Typography>
			<Typography>Double Check your URL is correct</Typography>
		</Stack>
	);
};

export default NotFound;
