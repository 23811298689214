import { Box, Button, ButtonBase, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import OptionCard from "./OptionCard";
import { useNavigate, useLocation } from "react-router-dom";
import {
	createEmptyPostFromId,
	createEmptyPostFromData,
} from "../../Utilities/firebase";
import { useAuth } from "../../Contexts/AuthContext";
import ProgressFooter from "./ProgressFooter";

const OpportunityType = () => {
	const [selected, setSelected] = useState(-1);

	const [selectedFullTime, setSelectedFullTime] = useState(false);
	const [selectedInternship, setSelectedInternship] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(false);
	const [selectedOther, setSelectedOther] = useState(false);

	const { currentUser } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const companyId = location.state.companyId;
	const companyData = location.state.companyData;

	const navigateNext = async () => {
		if (companyId) {
			if (selectedFullTime) {
				const postId = await createEmptyPostFromId(
					"Full-Time",
					companyId,
					currentUser["user_id"]
				);
				navigate("/postOpportunity/details", {
					state: { postId: postId, progress: 50, buffer: 75 },
				});
			} else if (selectedInternship) {
				const postId = await createEmptyPostFromId(
					"Internship",
					companyId,
					currentUser["user_id"]
				);
				navigate("/postOpportunity/details", {
					state: { postId: postId, progress: 50, buffer: 75 },
				});
			} else if (selectedEvent) {
				const postId = await createEmptyPostFromId(
					"Event",
					companyId,
					currentUser["user_id"]
				);
				navigate("/postOpportunity/details", {
					state: { postId: postId, progress: 50, buffer: 75 },
				});
			} else if (selectedOther) {
				const postId = await createEmptyPostFromId(
					"Other",
					companyId,
					currentUser["user_id"]
				);
				navigate("/postOpportunity/details", {
					state: { postId: postId, progress: 50, buffer: 75 },
				});
			}
		} else {
			if (selectedFullTime) {
				const postId = await createEmptyPostFromData(
					"Full-Time",
					companyData,
					currentUser["user_id"]
				);
				navigate("/postOpportunity/details", {
					state: { postId: postId, progress: 50, buffer: 75 },
				});
			} else if (selectedInternship) {
				const postId = await createEmptyPostFromData(
					"Internship",
					companyData,
					currentUser["user_id"]
				);
				navigate("/postOpportunity/details", {
					state: { postId: postId, progress: 50, buffer: 75 },
				});
			} else if (selectedEvent) {
				const postId = await createEmptyPostFromData(
					"Event",
					companyData,
					currentUser["user_id"]
				);
				navigate("/postOpportunity/details", {
					state: { postId: postId, progress: 50, buffer: 75 },
				});
			} else if (selectedOther) {
				const postId = await createEmptyPostFromData(
					"Other",
					companyData,
					currentUser["user_id"]
				);
				navigate("/postOpportunity/details", {
					state: { postId: postId, progress: 50, buffer: 75 },
				});
			}
		}
	};

	//TODO Define Going back and saving a Draft
	const navigateBack = () => {
		navigate("/postOpportunity/company", {
			state: {
				postId: location.state.postId ? location.state.postId : undefined,
				progress: 0,
				buffer: 25,
			},
		});
	};
	const saveDraft = () => {};

	const handleSelectFullTime = () => {
		setSelectedFullTime(true);
		setSelectedInternship(false);
		setSelectedEvent(false);
		setSelectedOther(false);
	};

	const handleSelectInternship = () => {
		setSelectedFullTime(false);
		setSelectedInternship(true);
		setSelectedEvent(false);
		setSelectedOther(false);
	};
	const handleSelectEvent = () => {
		setSelectedFullTime(false);
		setSelectedInternship(false);
		setSelectedEvent(true);
		setSelectedOther(false);
	};
	const handleSelectOther = () => {
		setSelectedFullTime(false);
		setSelectedInternship(false);
		setSelectedEvent(false);
		setSelectedOther(true);
	};
	return (
		<Box sx={{ mx: { xs: "0px", lg: "120px" } }}>
			<Stack
				sx={{
					mx: { xs: "120px", lg: "120px" },
					marginTop: "25px",
					rowGap: "50px",
				}}>
				<div>
					<Typography variant='h5' fontWeight={500}>
						Post an Opportunity
					</Typography>
					<Typography variant='body1'>2. Select Post Type</Typography>
				</div>
				<Box
					sx={{
						display: "flex",
						rowGap: "50px",
						alignItems: "center",
						flexDirection: "column",
						justifyContent: "center",
					}}>
					<Stack
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "40px",
							justifyContent: "center",
							flexWrap: "wrap",
						}}>
						<ButtonBase disableRipple onClick={handleSelectFullTime}>
							<OptionCard text='Full-time role' isActive={selectedFullTime} />
						</ButtonBase>
						<ButtonBase disableRipple onClick={handleSelectInternship}>
							<OptionCard text='Internship' isActive={selectedInternship} />
						</ButtonBase>

						<ButtonBase disableRipple onClick={handleSelectEvent}>
							<OptionCard text='Event' isActive={selectedEvent} />
						</ButtonBase>
						<ButtonBase disableRipple onClick={handleSelectOther}>
							<OptionCard text='Other' isActive={selectedOther} />
						</ButtonBase>
					</Stack>

					{/* <Button
						variant='contained'
						onClick={navigateNext}
						style={{
							maxWidth: "120px",
							maxHeight: "60px",
							minWidth: "120px",
							minHeight: "60px",
						}}>
						Next
					</Button> */}
				</Box>
			</Stack>
			<ProgressFooter
				progress={location.state ? location.state.progress : 0}
				buffer={location.state ? location.state.buffer : 25}
				nextDisabled={
					!(
						selectedFullTime ||
						selectedInternship ||
						selectedEvent ||
						selectedOther
					)
				}
				next={navigateNext}
				back={navigateBack}
				save={saveDraft}
			/>
		</Box>
	);
};

export default OpportunityType;
