import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { AuthProvider } from "./Contexts/AuthContext";
import PrivateRoute from "./Utilities/PrivateRoute";

import Profile from "./Components/Settings/Profile";
import Clubs from "./Components/Settings/Clubs/Clubs";
import Newsletter from "./Components/Settings/Newsletter";
import ClubDetails from "./Components/Settings/Clubs/ClubDetails";
import ClubManage from "./Components/Settings/Clubs/ClubManage/ClubManage";
import OpportunityType from "./Components/PostOpportunity/OpportunityType";
import OpportunitySelect from "./Components/PostOpportunity/OpportunitySelect";
import OpportunityDetails from "./Components/PostOpportunity/OpportunityDetails";
import OpportunityCompany from "./Components/PostOpportunity/OpportunityCompany";
import CompanyDetails from "./Components/PostOpportunity/CompanyDetails";
import GroupSelect from "./Components/PostOpportunity/GroupSelect";
import DefaultLayout from "./Components/Layouts/DefaultLayout";
import SettingsLayout from "./Components/Layouts/SettingsLayout";
import Landing from "./Components/Landing/Landing";
import JoinClubs from "./Components/Settings/Clubs/JoinClubs/JoinClubs";
import NoScrollLayout from "./Components/Layouts/NoScrollLayout";

import ViewPosts from "./Components/ViewPosts/ViewPosts";
import SignUp from "./Components/SignInSignUp/SignUp";
import SignIn from "./Components/SignInSignUp/SignIn";
import NotFound from "./Components/Common/NotFound";
import { useMediaQuery } from "@mui/material";
import AddGroup from "./Components/AddGroup/AddGroup";

import { Alert } from "@mui/material";
import InboundTermsOfService from "./Components/TOSPrivacyPolicy/InboundTOS";
import InboundPrivacyPolicy from "./Components/TOSPrivacyPolicy/PrivacyPolicy";
import TempMobile from "./Components/Common/TempMobile";

function App() {
	const mobile = useMediaQuery("(min-width:900px)");
	if (!mobile) {
		return <TempMobile />;
	}
	return (
		<div className='App'>
			<AuthProvider>
				<Routes>
					<Route element={<PrivateRoute signedIn />}>
						<Route path='/postOpportunity' element={<DefaultLayout />}>
							<Route
								index
								element={<Navigate to='/postOpportunity/company' />}
							/>
							<Route
								path='/postOpportunity/company'
								element={<OpportunityCompany />}
							/>
							<Route
								path='/postOpportunity/companyDetails'
								element={<CompanyDetails />}
							/>
							<Route
								path='/postOpportunity/type'
								element={<OpportunityType />}
							/>
							<Route
								path='/postOpportunity/details'
								element={<OpportunityDetails />}
							/>
							<Route
								path='/postOpportunity/select'
								element={<OpportunitySelect />}
							/>
							<Route path='/postOpportunity/groups' element={<GroupSelect />} />
						</Route>
						<Route path='/joingroups' element={<SettingsLayout />}>
							<Route index element={<JoinClubs />} />
						</Route>
						<Route path='/settings/groups' element={<SettingsLayout />}>
							<Route index element={<Clubs />} />
						</Route>
						<Route path='/settings/profile' element={<SettingsLayout />}>
							<Route index element={<Profile />} />
						</Route>
						<Route path='/settings' element={<SettingsLayout />}>
							<Route index element={<Profile />} />
						</Route>
						<Route path='/settings/groups/:clubId' element={<SettingsLayout />}>
							<Route index exact element={<ClubDetails />} />
						</Route>
						<Route
							path='/settings/groups/:clubId/manage'
							element={<SettingsLayout />}>
							<Route index exact element={<ClubManage />} />
						</Route>
						{/* <Route path='/settings' element={<SettingsLayout />}>
						<Route path='/settings/profile' element={<Profile />} />
						<Route index element={<Navigate to='/settings/profile' />} />
						<Route
							path='/settings/groups/:clubId'
							exact
							element={<ClubDetails />}
						/>
						<Route
							path='/settings/groups/:clubId/manage'
							exact
							element={<ClubManage />}
						/>
						<Route path='/settings/newsletter' element={<Newsletter />} />
					</Route> */}

						<Route path='/posts' element={<NoScrollLayout />}>
							<Route index element={<ViewPosts />} />
						</Route>
					</Route>
					<Route path='/signup' element={<DefaultLayout />}>
						<Route index element={<SignUp />} />
					</Route>

					<Route path='/signin' element={<DefaultLayout />}>
						<Route index element={<SignIn />} />
					</Route>

					<Route path='/addGroup' element={<AddGroup />} />

					<Route path='/termsofservice' element={<DefaultLayout />}>
						<Route index element={<InboundTermsOfService />} />
					</Route>

					<Route path='/privacypolicy' element={<DefaultLayout />}>
						<Route index element={<InboundPrivacyPolicy />} />
					</Route>

					<Route path='/' element={<DefaultLayout />}>
						<Route index element={<Landing />} />
					</Route>
					<Route path='*' element={<NotFound />} />
				</Routes>
			</AuthProvider>
		</div>
	);
}

export default App;
