import * as React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";

import Members from "./Members/Members";
import ClubExec from "./ClubExec/ClubExec";
import Requests from "./Requests/Requests";
import PlainLoading from "../../../../Loading/PlainLoading";

function TabPanel(props) {
  const { children, value, index, loading, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`club-manage-tabpanel-${index}`}
      aria-labelledby={`club-manage-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {loading ? <PlainLoading /> : <Typography>{children}</Typography>}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `club-manage-tab-${index}`,
    "aria-controls": `club-manage-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  exec,
  members,
  requests,
  clubRef,
  handleChangeExec,
  handleChangeMembers,
  handleChangeRequests,
  handleAddMembers,
}) {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event, newValue) => {
    setLoading(true);

    setTimeout(() => {
      setValue(newValue);
      setLoading(false);
    }, 500);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="club manage tabs"
        >
          <Tab label="Members" {...a11yProps(0)} />
          <Tab label="Group Leadership" {...a11yProps(1)} />
          <Tab label="Requests" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} loading={loading}>
        <Members
          members={members}
          handleChangeMembers={handleChangeMembers}
          handleChangeExec={handleChangeExec}
          clubRef={clubRef}
        />
      </TabPanel>
      <TabPanel value={value} index={1} loading={loading}>
        <ClubExec
          exec={exec}
          handleChangeExec={handleChangeExec}
          handleChangeMembers={handleChangeMembers}
          clubRef={clubRef}
        />
      </TabPanel>
      <TabPanel value={value} index={2} loading={loading}>
        <Requests
          requests={requests}
          handleChangeRequests={handleChangeRequests}
          handleAddMembers={handleAddMembers}
          clubRef={clubRef}
        />
      </TabPanel>
    </Box>
  );
}
