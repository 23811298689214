// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { useState, useEffect } from "react";
import {
	getAuth,
	GoogleAuthProvider,
	signInWithPopup,
	signOut,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	createUserWithEmailAndPassword,
	setPersistence,
	browserSessionPersistence,
	sendPasswordResetEmail,
} from "firebase/auth";
import {
	getFirestore,
	doc,
	addDoc,
	setDoc,
	getDoc,
	deleteDoc,
	getDocs,
	collection,
	updateDoc,
	arrayUnion,
	arrayRemove,
	Timestamp,
} from "firebase/firestore";
import { version } from "../Components/TOSPrivacyPolicy/PrivacyPolicy";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyDQ17HMsKEC8LD09HmxhYSRUCFZDUcqpfw",
	authDomain: "inbound-careers.firebaseapp.com",
	databaseURL: "https://inbound-careers-default-rtdb.firebaseio.com/",
	projectId: "inbound-careers",
	storageBucket: "inbound-careers.appspot.com",
	messagingSenderId: "428689903225",
	appId: "1:428689903225:web:6cd1f7ed188e4143de0ea8",
	measurementId: "G-V9SSLBJGGP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth();
(async () => {
	await setPersistence(auth, browserSessionPersistence);
})();

// Sign in with Google
export const signInWithG = async () => {
	signInWithPopup(getAuth(app), new GoogleAuthProvider());
};

// Sign in with Email/password
export const signIn = async (email, password) => {
	await signInWithEmailAndPassword(auth, email, password)
		.then(() => {})
		.catch((error) => {
			const errorMessage = error.message;
			alert(errorMessage.toString());
		});
};

// Sign up with Email/password, requires last name and first name to be updated in the user profile
export const signUp = async (
	email,
	password,
	last_name,
	first_name,
	university
) => {
	await createUserWithEmailAndPassword(auth, email, password)
		.then(async (userCredential) => {
			const user = userCredential.user;

			if (last_name !== "" && first_name !== "") {
				// updateDoc(doc(firestore, "users", user.uid, "last_name"), last_name);
				// updateDoc(doc(firestore, "users", user.uid, "first_name"), first_name);
				await setDoc(doc(firestore, "users", user.uid), {
					email: user.email,
					createdAt: new Date().toString(),
					clubs: [],
					exec_for: [],
					first_name: first_name,
					last_name: last_name,
					grad_month: 1,
					grad_year: 2022,
					major: "",
					saved_posts: [],
					university: university,
					race: "",
					gender: "",
					profile_pic: "",
					resume: "",
					user_id: user.uid,
					policy_version: parseInt(version),
				});
			}
		})
		.catch((error) => {
			const errorMessage = error.message;
			alert(errorMessage.toString());
		});
};

// Get User
export const getCurrentUser = async () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			resolve(user);
		}, reject);
	});
};
// Get User Id
export const getUID = async () => {
	return auth.currentUser.uid;
};

// Sign out
export const signOutUser = async () => {
	await signOut(auth);
};

// get user state who signed in to the account
export const useUserState = async () => {
	const [user, setUser] = useState(true);

	useEffect(() => {
		onAuthStateChanged(getAuth(app), (user) => {
			if (user) {
				setUser(user);
				// initializeNewUser(user);
			} else {
				setUser(null);
			}
		});
	}, [user]);
	return user;
};

// export const AllowIfAuth = () => {
//   const [user, loading, error] = useAuthState(auth);
//   if (loading) {
//       return "loading";
//   } else if (user) {
//       return "success";
//   } else {
//     return null;
//   }
// }

// create post with a post object (post_id will be automatically generated)
// Deprecated
export const createPost = async ({ post }) => {
	const docRef = doc(firestore, "posts");
	setDoc(docRef, post);
};

// get post with a post_id
export const getPost = async (post_id) => {
	const docRef = doc(firestore, "posts", post_id);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};

// get all posts
export const getPosts = async () => {
	const querySnapshot = await getDocs(collection(firestore, "posts"));
	const posts = [];
	querySnapshot.forEach((doc) => {
		posts.push(doc.data());
	});
	return posts;
};

// get club reference with a club_id and school_id
export const getClubRef = async (school_id, club_id) => {
	const docRef = doc(firestore, "schools", school_id, "clubs", club_id);
	return docRef;
};

// get club with a club_id and school_id
export const getClub = async (school_id, club_id) => {
	const docRef = doc(firestore, "schools", school_id, "clubs", club_id);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};
export const requestToClub = async (club_ref, user_id) => {
	const userRef = doc(firestore, "users", user_id);
	const data = await getClubFromReference(club_ref);
	let memberIDs = data.members.map((memberRef) => {
		return memberRef.id;
	});
	console.log(memberIDs, !memberIDs.includes(user_id));
	if (!memberIDs.includes(user_id)) {
		await updateDoc(club_ref, {
			requested: arrayUnion(userRef),
		});
		alert("Join request sent!");
	}
};

// get club with a club reference
export const getClubFromReference = async (club_ref) => {
	const docSnap = await getDoc(club_ref);
	if (docSnap.exists()) {
		var data = docSnap.data();
		data["id"] = docSnap.id;
		return data;
	} else {
		console.log("No such document!");
		return null;
	}
};

// get a list of clubs from a school_id in format [club, clubUID]
export const getClubs = async (school_id) => {
	const querySnapshot = await getDocs(
		collection(firestore, "schools", school_id, "clubs")
	);
	const clubs = [];
	querySnapshot.forEach((doc) => {
		const uid = doc.id;
		clubs.push([doc.data(), uid]);
	});
	return clubs;
};

// get a list of clubs a user is exec for
export const getUserExecClubs = async (user_id) => {
	const currentUser = await getUser(user_id);
	const userClubReferences = await currentUser["exec_for"];
	const userClubs = [];
	for (let i = 0; i < userClubReferences.length; i++) {
		let clubData = await getClubFromReference(userClubReferences[i]);
		userClubs.push([clubData]);
	}
	// Add UID to second index
	return userClubs;
};

// get post with a company id
export const getPostsByCompany = async (comany_id) => {
	const querySnapshot = await getDocs(collection(firestore, "posts"));
	const posts = [];
	querySnapshot.forEach((doc) => {
		if (doc.data().company_id === comany_id) {
			posts.push(doc.data());
		}
	});
	return posts;
};

export const getPostFromReference = async (post_ref) => {
	const docSnap = await getDoc(post_ref);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};

// update post with a post object
export const updatePost = async (post_id, post) => {
	const docRef = doc(firestore, "posts", post_id);
	await updateDoc(docRef, post);
};

// create a new user with a user object
export const createUser = async (user) => {
	const docRef = doc(firestore, "users", user.uid);
	setDoc(docRef, user);
};

// get user with a user_id
export const getUser = async (user_id) => {
	const docRef = doc(firestore, "users", user_id);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
};

// get all users
export const getUsers = async () => {
	const querySnapshot = await getDocs(collection(firestore, "users"));
	const users = [];
	querySnapshot.forEach((doc) => {
		users.push(doc.data());
	});
	return users;
};

// update user with a user object, if user is not found, create a new user
export const updateUser = async (user_id, user) => {
	const docRef = doc(firestore, "users", user_id);
	updateDoc(docRef, user);
};

export const acceptUsers = async (user_ids, clubRef) => {
	let newMembers = [];
	for (let uid of user_ids) {
		let userRef = doc(firestore, "users", uid);
		await updateDoc(clubRef, {
			members: arrayUnion(userRef),
			requested: arrayRemove(userRef),
		});
		await updateDoc(userRef, {
			clubs: arrayUnion(clubRef),
		});
		let newMember = await getUser(uid);
		newMembers.push(newMember);
	}
	return newMembers;
};

export const rejectUsers = async (user_ids, clubRef) => {
	for (let uid of user_ids) {
		let userRef = doc(firestore, "users", uid);
		await updateDoc(clubRef, {
			requested: arrayRemove(userRef),
		});
	}
};

export const promoteUsers = async (user_ids, clubRef) => {
	for (let uid of user_ids) {
		let userRef = doc(firestore, "users", uid);
		await updateDoc(clubRef, {
			execs: arrayUnion(userRef),
		});
		await updateDoc(userRef, {
			exec_for: arrayUnion(clubRef),
		});
	}
};

export const demoteUsers = async (user_ids, clubRef) => {
	for (let uid of user_ids) {
		let userRef = doc(firestore, "users", uid);
		await updateDoc(clubRef, {
			execs: arrayRemove(userRef),
		});
		await updateDoc(userRef, {
			exec_for: arrayRemove(clubRef),
		});
	}
};

export const removeUsers = async (user_ids, clubRef) => {
	for (let uid of user_ids) {
		let userRef = doc(firestore, "users", uid);
		await updateDoc(clubRef, {
			members: arrayRemove(userRef),
			execs: arrayRemove(userRef),
		});
		await updateDoc(userRef, {
			clubs: arrayRemove(clubRef),
			exec_for: arrayRemove(clubRef),
		});
	}
};

export const removeUser = async (user_id, school_id, club_id) => {
	const userRef = doc(firestore, "users", user_id);
	const clubRef = doc(firestore, "schools", school_id, "clubs", club_id);
	await updateDoc(clubRef, {
		members: arrayRemove(userRef),
		execs: arrayRemove(userRef),
	});
	await updateDoc(userRef, {
		clubs: arrayRemove(clubRef),
		exec_for: arrayRemove(clubRef),
	});
};

export const addGroup = async (school_id, groupData) => {
	const docRef = await addDoc(
		collection(firestore, "schools", school_id, "clubs"),
		{
			description: groupData.description,
			name: groupData.name,
			logo: groupData.link,
			execs: [],
			members: [],
			posts: [doc(firestore, "posts", "DZWSNKVFOc8M9ugmeEHJ")],
			requested: [],
		}
	);
	alert(`Succsessfully added document ${docRef} for group ${groupData.name}`);
};

// setData on a specific path
export const setData = (path, value) => setDoc(doc(firestore, path), value);

export const pushToFirebase = async (route, userid, data) => {
	if (data) {
		try {
			await setData(`users/${userid}/info/${route}`, data);
			console.log("pushed to firebase");
		} catch (error) {
			alert(error);
		}
	}
};

export const resetPassword = async (email) => {
	await sendPasswordResetEmail(auth, email)
		.then(() => {
			alert("Password reset email sent!");
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			alert("errorMessage");
		});
};

export const changePassword = async (email) => {
	await sendPasswordResetEmail(auth, email)
		.then(() => {
			alert("Please check your email to change your password!");
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			alert("errorMessage");
		});
};

// creates an empty post for user to edit and adds it as a "draft" to their company
export const createEmptyPost = async (jobType, companyRef) => {
	const companySnap = await getDoc(companyRef);
	let companyData;
	if (companySnap.exists()) {
		companyData = companySnap.data();
	} else {
		console.log("No such document!");
		return null;
	}

	const postRef = await addDoc(collection(firestore, "posts"), {
		company: companyData.name,
		image: companyData.logo,
		job_type: jobType,
		date_created: Timestamp.now(),
	});
	setDoc(postRef, { id: postRef.id }, { merge: true });
	return (await postRef).id;
};

export const editPost = async (post_id, newData) => {
	const postRef = await doc(firestore, "posts", post_id);
	await setDoc(postRef, newData, { merge: true });
	alert("Post Saved");
};

export const modifyGroupsOnPost = async (post_id, ids) => {
	const postRef = await doc(firestore, "posts", post_id);
	const newArray = ids.map((obj) => {
		const groupRef = doc(
			firestore,
			"schools",
			obj.school_id,
			"clubs",
			obj.group_id
		);
		// Need await?
		updateDoc(groupRef, {
			posts: arrayUnion(postRef),
		});
		return groupRef;
	});
	// console.log(postRef, newArray);
	await setDoc(postRef, { posted_to: newArray }, { merge: true });
	alert("Successfully Sent to Groups");
};

export const modifyGroupOnPost = async (post_id, id) => {
	const postRef = await doc(firestore, "posts", post_id);
	const groupRef = doc(
		firestore,
		"schools",
		id.school_id,
		"clubs",
		id.group_id
	);
	// Need await?
	updateDoc(groupRef, {
		posts: arrayUnion(postRef),
	});
	updateDoc(groupRef, {
		group_posts: arrayUnion(postRef),
	});
	const newArray = Array(groupRef);
	// console.log(postRef, newArray);
	await setDoc(postRef, { posted_to: newArray }, { merge: true });
	alert("Successfully Sent to Group");
};

export const deletePost = async (post_id) => {
	await deleteDoc(doc(firestore, "posts", post_id));
};

// get a list of all companies
export const getCompanies = async () => {
	const querySnapshot = await getDocs(collection(firestore, "companies"));
	const companies = [];
	querySnapshot.forEach((doc) => {
		const uid = doc.id;
		companies.push([doc.data(), uid]);
	});
	return companies;
};

// creates an empty post for user to edit
export const createEmptyPostFromId = async (jobType, company_id, user_id) => {
	const companyRef = await doc(firestore, "companies", company_id);
	const companySnap = await getDoc(companyRef);
	let companyData;
	if (companySnap.exists()) {
		companyData = companySnap.data();
	} else {
		console.log("No such document!");
		return null;
	}
	// console.log(companyData);

	const postRef = await addDoc(collection(firestore, "posts"), {
		company: companyData.name,
		image: companyData.logo,
		job_type: jobType,
		date_created: Timestamp.now(),
	});
	setDoc(postRef, { id: postRef.id }, { merge: true });

	// Add PostRef to user
	const userRef = doc(firestore, "users", user_id);
	await updateDoc(userRef, {
		saved_posts: arrayUnion(postRef),
	});
	return (await postRef).id;
};

// creates an empty post for user to edit
export const createEmptyPostFromData = async (
	jobType,
	companyData,
	user_id
) => {
	const postRef = await addDoc(collection(firestore, "posts"), {
		company: companyData.name,
		image: companyData.logo,
		job_type: jobType,
		date_created: Timestamp.now(),
	});
	setDoc(postRef, { id: postRef.id }, { merge: true });

	// Add PostRef to user
	const userRef = doc(firestore, "users", user_id);
	await updateDoc(userRef, {
		saved_posts: arrayUnion(postRef),
	});
	return (await postRef).id;
};

export const getAllUniversities = async () => {
	const querySnapshot = await getDocs(collection(firestore, "schools"));
	const schools = [];
	querySnapshot.forEach((doc) => {
		schools.push({ data: doc.data(), id: doc.id, ref: doc.ref });
	});
	return schools;
};
