import {
	Box,
	Button,
	ButtonBase,
	Stack,
	Typography,
	TextField,
	MenuItem,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createEmptyPost, getCompanies } from "../../Utilities/firebase";
import { useAuth } from "../../Contexts/AuthContext";
import ProgressFooter from "./ProgressFooter";

const OpportunityCompany = () => {
	const [selected, setSelected] = useState(-1);
	const locationState = useLocation().state;

	const [companyId, setCompanyId] = useState(null);

	const { currentUser, companies } = useAuth();
	const navigate = useNavigate();

	const navigateBack = () => {};
	const saveDraft = () => {};
	// useEffect(() => {
	// 	const fetchData = () => {
	// 		if (locationState.postId){
	// 			setCompanyId(l)
	// 		}
	// 	}
	//   fetchData()
	// }, [])

	const navigateNext = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const companyData = data.get("company");
		setCompanyId(companyData);
		if (companyData) {
			navigate("/postOpportunity/type", {
				state: {
					companyId: companyData,
					companyData: null,
					progress: 25,
					buffer: 50,
				},
			});
		}
	};

	const navigateCompanyDetails = (async) => {
		navigate("/postOpportunity/companyDetails");
	};

	return (
		<Box
			sx={{ mx: { xs: "0px", lg: "120px" } }}
			onSubmit={navigateNext}
			autoComplete='off'
			component='form'>
			<Stack
				sx={{
					mx: { xs: "120px", lg: "120px" },
					marginTop: "25px",
					rowGap: "50px",
				}}>
				<div>
					<Typography variant='h5' fontWeight={500}>
						Post an Opportunity
					</Typography>
					<Typography variant='body1'>1. Select Company</Typography>
				</div>
				<Box
					sx={{
						display: "flex",
						rowGap: "50px",
						alignItems: "center",
						flexDirection: "column",
						justifyContent: "center",
					}}>
					<Stack
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "40px",
							justifyContent: "center",
							flexWrap: "wrap",
						}}>
						<TextField
							select
							id='company'
							name='company'
							label='Company'
							sx={{
								minWidth: 400,
							}}
							required>
							{companies.map((company) => (
								<MenuItem
									key={company[1]}
									value={company[1]}
									sx={{
										minWidth: 400,
									}}>
									{company[0].name}
								</MenuItem>
							))}
						</TextField>
					</Stack>
					<Stack
						alignItems='center'
						justifyContent='center'
						display='flex'
						spacing={2}>
						<Button
							type='submit'
							variant='contained'
							style={{
								maxWidth: "120px",
								maxHeight: "60px",
								minWidth: "120px",
								minHeight: "60px",
							}}>
							Next
						</Button>
						<Button
							onClick={navigateCompanyDetails}
							style={{
								maxWidth: "220px",
							}}>
							Company not listed? Add details manually
						</Button>
					</Stack>
				</Box>
			</Stack>
			<ProgressFooter
				progress={0}
				buffer={25}
				submitNeeded={true}
				next={navigateNext}
				back={navigateBack}
				save={saveDraft}
			/>
		</Box>
	);
};

export default OpportunityCompany;
