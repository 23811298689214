import React from "react";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { Grid, Paper, Stack } from "@mui/material";

const Img = styled("img")({
	margin: "auto",
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
});

function shortenLocations(locationArray) {
	let arrLength = locationArray.length;
	let leftover = arrLength - 1;
	let text;
	if (leftover > 0) {
		text = `${locationArray[0]} + ${leftover} more`;
	} else {
		text = `${locationArray[0]}`;
	}
	return text;
}
const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
	"&.Mui-active": {
		border: `4px solid ${theme.palette.primary}`,
	},
}));

const ListingCard = ({ listing, isActive, clickFunction }) => {
	const locations = shortenLocations(listing.locations);
	return (
		<Paper
			elevation={3}
			sx={{
				mt: 1,
				mx: 2,
				border: 2,
				borderColor: isActive ? "primary.main" : "transparent",
				borderRadius: "30px",
				minWidth: { xs: "100%", sm: "100%", md: "400px", lg: "515px" },
				maxWidth: "515px",
			}}>
			<StyledButtonBase
				sx={{
					justifyContent: "left",
					width: "100%",
					borderRadius: "30px",
					px: "35px",
					py: "23px",
					"&.Mui-active": {
						border: "4px solid #482FD7",
					},
				}}
				onClick={() => clickFunction(listing.id)}>
				<Stack gap={2} width='100%' direction="horizontal" justifyContent='left' whiteSpace='nowrap'>
					<Grid item>
						<Img
							alt={listing.company}
							src={listing.image}
							sx={{ width: 108, height: 108, borderRadius: "5px" }}
						/>
					</Grid>
					<Grid  item xs sm container width="10%" >
						<Grid
							item
							xs
							container
							spacing={2}
							>
							<Grid item xs>
								<Typography
									textAlign={"left"}
									gutterBottom
									variant='h6'
									whiteSpace='nowrap'
									fontWeight={"bold"}
									textOverflow='ellipsis'
									overflow='hidden'
									component='div'>
									{listing.title}
								</Typography>
								<Typography variant='body2' gutterBottom 
									textAlign={"left"} 
									textOverflow='ellipsis' 
									whiteSpace='nowrap'
									overflow='hidden'>
									{`${listing.company} | ${locations}`}
									
								</Typography>
								<Typography
									variant='body2'
									color='text.secondary'
									textAlign={"left"}>
									{listing.job_type}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Stack>
			</StyledButtonBase>
		</Paper>
	);
};

export default ListingCard;
