import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Img = styled("img")({
	margin: "auto",
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
	objectFit: "cover",
});

const PostedToIcons = ({ clubs }) => {
	return (
		<Box sx={{ display: "flex",	overflow: "auto", flexWrap: "nowrap"}}>
			{clubs.map((club) => {
				return (
					<Img
						key={club.name}
						alt={club.name}
						src={club.imageUrl}
						sx={{
							width: 108,
							height: 108,
							borderRadius: "12px",
							mr: 3,
							my: 0,
							ml: 0,
						}}
					/>
				);
			})}
		</Box>
	);
};

export default PostedToIcons;
