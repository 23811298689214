import * as React from "react";
import { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { signIn, getUser, getCurrentUser } from "../../Utilities/firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext"

export default function SignIn() {
	const navigate = useNavigate();
	const { currentUser } = useAuth();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		await signIn(data.get("email"), data.get("password"));
	};

	useEffect(() => {
		if (currentUser) {
			navigate("/posts");
		}
	  }, [currentUser]);

	return (
		<Container component='main' maxWidth='xs' sx={{ marginBlock: "auto" }}>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Sign in
				</Typography>
				<Box
					component='form'
					noValidate
					onSubmit={(e) => {
						handleSubmit(e);
					}}
					sx={{ mt: 3 }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								autoFocus
								name='email'
								id='email'
								label='Email Address'
								autoComplete='email'
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								name='password'
								id='password'
								label='Password'
								type='password'
								autoComplete='new-password'
							/>
						</Grid>
					</Grid>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2 }}>
						Sign In
					</Button>
					<Grid container justifyContent='flex-end'>
						<Grid item>
							<Link component={RouterLink} to='/signup' variant='body2'>
								Don't have an account? Sign up
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Container>
	);
}