import { Search, KeyboardBackspace } from "@mui/icons-material";
import {
	Box,
	Button,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	getClubs,
	getPost,
	modifyGroupOnPost,
	getUserExecClubs,
} from "../../Utilities/firebase";
import GroupPaper from "./GroupPaper";
import { useAuth } from "../../Contexts/AuthContext";
import NotExec from "./NotExec";
import NoGroupsFound from "./NoGroupsFound";
import ProgressFooter from "./ProgressFooter";

const ClubSelect = () => {
	const [search, setSearch] = useState("");
	const [groups, setGroups] = useState([]);
	const [selected, setSelected] = useState(null);
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const navigate = useNavigate();
	const postId = location.state.postId;
	console.log(location.state);
	const { currentUser } = useAuth();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const postData = await getPost(postId);
			const userClubs = await getUserExecClubs(currentUser["user_id"]);
			if (postData.posted_to) {
			}
			setGroups(userClubs);
			setLoading(false);
		};

		fetchData().catch(console.error);
	}, []);

	const _handleSearchChange = (event) => {
		setSearch(event.target.value);
	};

	const handleSetSelected = (id, isAdd) => {
		if (isAdd) {
			setSelected(id);
		} else {
			setSelected(null);
		}
	};

	const handleBack = () => {
		navigate("/postOpportunity/details", {
			state: { postId: postId, progress: 50, buffer: 75 },
		});
	};
	//TODO Define Going back and saving a Draft
	const saveDraft = () => {};

	const handleSubmit = async () => {
		let selectedGroup = {
			school_id: currentUser.university,
			group_id: selected,
		};
		await modifyGroupOnPost(postId, selectedGroup);
		navigate("/posts");
	};

	if (groups.length == 0 && !loading) {
		return (
			<Box
				marginX={"100px"}
				marginY={"2rem"}
				display='flex'
				flexDirection='column'
				gap={"25px"}>
				<Box
					display='flex'
					justifyContent={"space-between"}
					alignItems='center'>
					<Box
						onClick={handleBack}
						display='flex'
						gap={"14px"}
						alignItems={"center"}>
						<KeyboardBackspace />
						<Typography>Back</Typography>
					</Box>
					<Button
						onClick={handleSubmit}
						variant='contained'
						disabled={selected ? false : true}>
						Submit
					</Button>
				</Box>
				<div>
					<Typography variant='h5' fontWeight={500}>
						Post an Opportunity
					</Typography>
					<Typography variant='body1'>4. Select Groups</Typography>
				</div>
				<Box>
					<TextField
						id='input-with-icon-textfield'
						fullWidth
						value={search}
						onChange={_handleSearchChange}
						placeholder='Search for groups...'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Search />
								</InputAdornment>
							),
						}}
						variant='standard'
					/>
				</Box>
				<NotExec />
			</Box>
		);
	}

	return (
		<Box
			marginX={"100px"}
			marginY={"2rem"}
			display='flex'
			flexDirection='column'
			gap={"25px"}>
			{/* <Box display='flex' justifyContent={"space-between"} alignItems='center'>
				<Box
					onClick={handleBack}
					display='flex'
					gap={"14px"}
					alignItems={"center"}>
					<KeyboardBackspace />
					<Typography>Back</Typography>
				</Box>
				<Button
					onClick={handleSubmit}
					variant='contained'
					disabled={!selected ? true : false}>
					Submit
				</Button>
			</Box> */}
			<div>
				<Typography variant='h5' fontWeight={500}>
					Post an Opportunity
				</Typography>
				<Typography variant='body1'>4. Select Group</Typography>
			</div>
			<Box>
				<TextField
					id='input-with-icon-textfield'
					fullWidth
					value={search}
					onChange={_handleSearchChange}
					placeholder='Search for groups...'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Search />
							</InputAdornment>
						),
					}}
					variant='standard'
				/>
			</Box>
			<Box display={"flex"} flexDirection='column' gap='28px'>
				{groups.map((group) => {
					if (!group[0].name.toLowerCase().includes(search.toLowerCase())) {
						return;
					}
					if (selected == group[0].id) {
						return (
							<GroupPaper
								active={true}
								group={group}
								key={group[0].id}
								handleSetSelected={handleSetSelected}
							/>
						);
					}
					return (
						<GroupPaper
							active={false}
							group={group}
							key={group[0].id}
							handleSetSelected={handleSetSelected}
						/>
					);
				})}
				{/*
					() => {
						let matches = groups.filter(group => group[0].name.toLowerCase().includes(search.toLowerCase()));
						if (!matches) {
							return;
						} else {
							matches.map((group) => {
								if (selected == group[0].id) {
									return (
										<GroupPaper
											active={true}
											group={group}
											key={group[0].id}
											handleSetSelected={handleSetSelected}
										/>
									);
								}
								return (
									<GroupPaper
										active={false}
										group={group}
										key={group[0].id}
										handleSetSelected={handleSetSelected}
									/>
								);
							});
						}
					}
				*/}
			</Box>
			<ProgressFooter
				progress={location.state.progress ? location.state.progress : 0}
				buffer={location.state.buffer ? location.state.buffer : 25}
				next={handleSubmit}
				nextDisabled={!selected ? true : false}
				back={handleBack}
				save={saveDraft}
			/>
		</Box>
	);
};

export default ClubSelect;
