import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, { useState } from "react";

const GroupPaper = ({ group, active, handleSetSelected }) => {
	const [open, setOpen] = useState(false);

	const handleOpen = (evt) => {
		evt.stopPropagation();
		if (
			evt.target.id !== "arrow-button-icon" &&
			evt.target.id !== "arrow-button-button"
		) {
			return;
		}
		if (open) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	};

	const handleClick = (evt) => {
		if (
			evt.target.id == "arrow-button-icon" ||
			evt.target.id == "arrow-button-button"
		) {
			return;
		}
		handleSetSelected(group[0].id, !active);
	};

	return (
		<Box
			id='paper-box'
			onClick={(evt) => handleClick(evt)}
			sx={{
				boxSizing: "border-box",
				borderRadius: "20px",
				border: `#482fd7 solid ${active ? "3px" : "0px"}`,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
				transition: "height 0.3s ease-in",
				paddingY: "30px",
				paddingLeft: "50px",
				paddingRight: "40px",
				boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
				cursor: "pointer",
			}}>
			<Box
				display={"flex"}
				alignItems='center'
				justifyContent='space-between'
				flexGrow={1}>
				<Box gap='20px' display={"flex"} alignItems='center'>
					<Box
						component='img'
						src={group[0].logo}
						sx={{
							height: 75,
							width: 75,
							maxHeight: { xs: 75, md: 75 },
							maxWidth: { xs: 75, md: 75 },
							borderRadius: "10px",
							objectFit: "contain",
						}}
					/>
					<Typography>{`${group[0].name}`} {/* ` •` */}</Typography>
					{/* <Typography>{`${group[2]}`}</Typography> */}
				</Box>
				<Box display={"flex"} alignItems='center'>
					<Typography marginRight={"6px"}>
						{`${group[0].members.length} Member${
							group[0].members.length > 1 ? "s" : ""
						}`}
					</Typography>
					<IconButton
						id='arrow-button-button'
						onClick={(evt) => handleOpen(evt)}
						sx={{
							transition: "all 0.3s",
							transform: `${open ? "rotate(90deg)" : "rotate(0deg)"}`,
						}}>
						<KeyboardArrowRightIcon id='arrow-button-icon' />
					</IconButton>
				</Box>
			</Box>
			<Box
				sx={{
					overflow: "hidden",
					maxHeight: `${open ? "200px" : "0px"}`,
					transformOrigin: "top",
					transition: "all 0.3s",
				}}>
				<Typography>{group[0].description}</Typography>
			</Box>
		</Box>
	);
};

export default GroupPaper;