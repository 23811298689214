import { Box, Typography } from "@mui/material";
import React from "react";

const TempMobile = () => {
	return (
		<Box textAlign={"center"} paddingTop={"1rem"}>
			<Typography variant='h5'>Mobile not yet Supported</Typography>
			<Typography variant='body1'>
				Please use a desktop or laptop device to access Inbound
			</Typography>
		</Box>
	);
};

export default TempMobile;
